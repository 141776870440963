import Vue from 'vue';
import ECharts from 'vue-echarts'; // refers to components/ECharts.vue in webpack

// import ECharts modules manually to reduce bundle size
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';

export default () => {
  Vue.component('v-chart', ECharts);
};
