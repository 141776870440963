import { render, staticRenderFns } from "./default.vue?vue&type=template&id=153a0fd2&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=153a0fd2&prod&lang=css&"
import style1 from "./default.vue?vue&type=style&index=1&id=153a0fd2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "153a0fd2",
  null
  
)

export default component.exports