<template>
  <b-dropdown
    :no-caret="noCaret"
    :right="right"
    :toggle-class="toogleClass"
    :variant="variant"
    :disabled="disabled"
  >
    <template #button-content>
      <slot name="button-content">
        <fa-icon :icon="['fas', 'ellipsis']" />
      </slot>
    </template>
    <template #default>
      <slot>
        <b-dropdown-item
          v-for="(item, index) in items"
          :key="index"
          @click="item.action"
        >
          {{ item.label }}
        </b-dropdown-item>
      </slot>
    </template>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    noCaret: {
      type: Boolean,
      default: true,
    },
    right: {
      type: Boolean,
      default: true,
    },
    toogleClass: {
      type: String,
      default: 'text-secondary',
    },
    variant: {
      type: String,
      default: 'light',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown {
  display: inline-block;
}
</style>
