<template>
  <div :class="{ 'show-menu': isMenuOpen }" class="app-menu">
    <nav class="app-nav">
      <div>
        <div class="app-nav-list">
          <nuxt-link
            v-for="(item, index) in menuItems"
            class="app-nav-link text-nowrap"
            :key="index"
            :to="{ path: item.url }"
            :alt="item.name"
          >
            <fa-icon class="app-nav-icon" :icon="item.icon" />
            <span>{{ item.name }}</span>
          </nuxt-link>
        </div>
      </div>
      <button @click="toggleMenu" class="btn app-nav-link" type="button">
        <fa-icon v-if="isMenuOpen" class="app-nav-icon" icon="angle-left" />
        <fa-icon v-else class="app-nav-icon" icon="angle-right" />
      </button>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    menuService: {
      type: Object,
      default: () => {},
    },
    toggleMenu: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
:root {
  --nav-width: 68px;
  --first-color-light: #fff;
  --white-color: #7fccef;
  --normal-font-size: 1rem;
  --z-fixed: 100;
}
*,
::before,
::after {
  box-sizing: border-box;
}
body {
  position: relative;
  margin: 0;
  padding: 0;
  transition: 0.5s;
}
a.app-nav-link,
button.app-nav-link {
  text-decoration: none;
}
.app-menu {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: white;
  border-right: 1px solid #cccccc;
  padding: 1.2rem 0.8rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}
.app-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.app-nav-logo,
a.app-nav-link,
button.app-nav-link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
}
.app-nav-logo {
  margin-bottom: 2rem;
  padding: 0 0 0.5rem 1.6rem;
}
.app-nav-logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
}
.app-nav-logo-name {
  color: var(--white-color);
  font-weight: 700;
}
a.app-nav-logo:hover {
  text-decoration: none;
}
.app-nav-list {
  margin-top: 68px;
}
a.app-nav-link,
button.app-nav-link {
  position: relative;
  color: black;
  margin-bottom: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  transition: 0.3s;
}
a.app-nav-link:hover,
button.app-nav-link:hover {
  color: #4d92e3;
  text-decoration: none;
}
a.app-nav-link:hover span,
button.app-nav-link:hover span {
  background-color: white;
  width: 100px;
}
.app-nav-icon {
  font-size: 1.2rem;
  width: 1.25em !important;
}
.show-menu {
  left: 0;
}
.body-pd {
  padding-left: var(--nav-width);
}
@media screen and (min-width: 768px) {
  body {
    padding-left: var(--nav-width);
  }
  .app-menu {
    left: 0;
    padding: 1rem 0.8rem 0 0;
  }
  .show-menu {
    width: calc(var(--nav-width) + 156px);
  }
  .body-pd {
    padding-left: calc(var(--nav-width) + 156px);
  }
}
</style>
