import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

/**
 * Use this if you only want to log errors but not notify the user
 *
 * In component use:
 *  this.$error(ex)
 *  this.$error('Error to send')
 */
export default (context, inject: any) => {
  const StrossleError = (error: Error) => {
    if (error && typeof error !== 'string') {
      if (Sentry) {
        Sentry.captureException(error);
      }
      if (LogRocket) {
        LogRocket.captureException(error);
      }
    }
  };
  // globally inject LogRocket instance
  inject('error', StrossleError);
};
