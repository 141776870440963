import { uuidv4 } from '../utils';

export default function({ $axios, store }) {
  // declare a request interceptor
  $axios.interceptors.request.use(
    config => {
      // perform a task before the request is sent
      if (config.url.indexOf(process.env.restApiEndpoint) === 0) {
        config.headers['X-Request-ID'] = uuidv4();
      }
      return config;
    },
    error => {
      // handle the error
      return Promise.reject(error);
    }
  );
}
