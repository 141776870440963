import deepmerge from 'deepmerge';

export const defaults = {
  appSettings: {
    logo: {
      url: 'https://assets.strossle.com/images/strossle_logo_azerion.png',
    },
    logoSmall: {
      url: '/img/strosslelogominimized.svg',
    },
    quickNavigation: {
      items: [
        {
          id: 'marketplace',
          name: 'Marketplace',
          description: 'Setup and manage your campaigns.',
          domain: process.env.marketplaceDomain,
          icon: {
            url: '/img/icon-marketplace.svg',
          },
        },
        {
          id: 'insights',
          name: 'Insights',
          description:
            'See how your campaigns and widgets are performing and check your revenue and budgets.',
          domain: process.env.insightsDomain,
          icon: {
            url: '/img/icon-insights.svg',
          },
        },
        {
          id: 'manager',
          name: 'Manager',
          description: 'Manage your groups and users.',
          domain: process.env.managerDomain,
          icon: {
            url: '/img/icon-manager.svg',
          },
        },
        {
          id: 'factory',
          name: 'Factory',
          description: 'Create, edit and view your widgets.',
          domain: process.env.factoryDomain,
          icon: {
            url: '/img/icon-factory.svg',
          },
        },
      ],
    },
    products: {
      my: {
        pages: {
          '/home': {
            texts: {
              welcome: 'Welcome to Strossle!',
            },
            productLinks: [
              {
                id: 'marketplace',
                name: 'Marketplace',
                description: 'Setup and manage your campaigns.',
                domain: process.env.marketplaceDomain,
                logo: {
                  url: '/img/icon-marketplace.svg',
                },
              },
              {
                id: 'insights',
                name: 'Insights',
                description:
                  'See how your campaigns and widgets are performing and check your revenue and budgets.',
                domain: process.env.insightsDomain,
                logo: {
                  url: '/img/icon-insights.svg',
                },
              },
              {
                id: 'manager',
                name: 'Manager',
                description: 'Manage your groups and users.',
                domain: process.env.managerDomain,
                logo: {
                  url: '/img/icon-manager.svg',
                },
              },
              {
                id: 'factory',
                name: 'Factory',
                description: 'Create, edit and view your widgets.',
                domain: process.env.factoryDomain,
                logo: {
                  url: '/img/icon-factory.svg',
                },
              },
            ],
          },
          '/signup': {
            texts: {
              welcome: 'Welcome to Strossle!',
            },
          },
        },
      },
      marketplace: {
        navigation: {
          items: [
            {
              id: 'all-campaigns',
              name: 'Campaigns',
              url: '/campaigns?status=ALL',
              icon: ['fal', 'list'],
            },
            {
              id: 'new-campaign',
              name: 'New Campaign',
              url: '/campaigns/create',
              icon: ['fal', 'circle-plus'],
            },
            {
              id: 'approvals',
              name: 'Pending Review',
              url: '/approvals',
              icon: ['fal', 'circle-check'],
              strossleOnly: true,
            },
            {
              id: 'site-list',
              name: 'Site List',
              url: '/sites',
              icon: ['fal', 'globe'],
              strossleOnly: true,
            },
          ],
        },
      },
      insights: {
        navigation: {
          items: [
            {
              id: 'widget-report',
              name: 'Widget Report',
              url: '/widgets',
              icon: ['fal', 'grid-2'],
            },
            {
              id: 'campaign-report',
              name: 'Campaign Report',
              url: '/campaigns',
              icon: ['fal', 'chart-simple'],
            },
          ],
        },
      },
      manager: {
        navigation: {
          items: [
            {
              name: 'Groups',
              url: '/groups',
              icon: ['fal', 'share-nodes'],
            },
            {
              name: 'Packages',
              url: '/packages',
              icon: ['fal', 'box-open'],
              strossleOnly: true,
            },
          ],
        },
      },
      factory: {
        navigation: {
          items: [
            {
              id: 'placements',
              name: 'Placements',
              url: '/widgets',
              icon: ['fal', 'list'],
            },
            {
              id: 'new-placement',
              name: 'New Placement',
              url: '/widgets/new',
              icon: ['fal', 'circle-plus'],
            },
            {
              id: 'sites',
              name: 'Sites',
              url: '/sites',
              icon: ['fal', 'globe'],
            },
            /*{
              id: 'scrapers',
              name: 'Scrapers',
              url: '/scrapers',
              icon: ['fal', 'spider'],
              strossleOnly: true,
            },*/
          ],
        },
      },
    },
  },
  userSettings: {},
};

export const leadbooster = deepmerge(defaults, {
  appSettings: {
    logo: {
      url:
        'https://assets.strossle.com/images/leadbooster/leadbooster-2r-1@2x.png',
    },
    logoSmall: {
      url:
        'https://assets.strossle.com/images/leadbooster/small-favico-1@2x.png',
    },
    products: {
      my: {
        pages: {
          '/home': {
            texts: {
              welcome: 'Welcome to Leadbooster!',
            },
          },
          '/signup': {
            texts: {
              welcome: 'Welcome to Leadbooster!',
            },
          },
        },
      },
    },
  },
});

// Remove widget navigation in insights
leadbooster.appSettings.products.insights.navigation.items = leadbooster.appSettings.products.insights.navigation.items.filter(
  item => item.id !== 'widget-report'
);

// Set enabled products in quickNavigation
leadbooster.appSettings.quickNavigation.items = leadbooster.appSettings.quickNavigation.items.filter(
  p => ['marketplace', 'insights', 'manager'].includes(p.id)
);

// Set enabled products in my home page
leadbooster.appSettings.products.my.pages[
  '/home'
].productLinks = leadbooster.appSettings.products.my.pages[
  '/home'
].productLinks.filter(p =>
  ['marketplace', 'insights', 'manager'].includes(p.id)
);

// Add menu item
Object.values(leadbooster.appSettings.products).forEach(product => {
  if (
    product.navigation &&
    product.navigation.items &&
    Array.isArray(product.navigation.items)
  ) {
    product.navigation.items.push({
      name: 'Go to Leadbooster',
      url: 'https://app.leadbooster.no',
      icon: 'icon-star',
    });
  }
});
