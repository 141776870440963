import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

export default ({ store }) => {
  const environment = process.env.environment;
  const release = `ui@${process.env.version || process.env.sha1}`; // must comply with release tag in build (e.g. circle)
  const dsn = process.env.sentry;

  if (!dsn) {
    return;
  }

  if (['production', 'staging'].includes(process.env.environment)) {
    Sentry.init({
      environment,
      release,
      debug: environment !== 'production',
      dsn,
      integrations: [
        new Integrations.BrowserTracing(),
        new VueIntegration({
          Vue,
          attachProps: true,
          tracing: true,
          tracingOptions: {
            trackComponents: true,
          },
        }),
      ],
      tracesSampleRate: 0.2,
    });
  } else {
    console.log('Skip Sentry.');
  }

  store.subscribe((mutation, state) => {
    if (
      mutation.type === 'auth/SET' &&
      mutation.payload &&
      mutation.payload.key === 'user'
    ) {
      const id = mutation.payload.value['https://strossle.com/user_id'];
      Sentry.configureScope((scope) => {
        scope.setUser({ id });
      });
    }

    if (mutation.type.indexOf('marketplace/') === 0) {
      Sentry.configureScope((scope) => {
        scope.setExtra('state.marketplace', state.marketplace);
      });
    }
  });
};
