import Auth0Lock from 'auth0-lock';

export default async function({ $auth, $axios }) {
  const getLock = () => {
    const options = {
      configurationBaseUrl: 'https://cdn.eu.auth0.com',
      closable: false,
      rememberLastLogin: false,
      auth: {
        audience: process.env.authAudience,
        responseType: 'token id_token',
        redirectUrl:
          `${window.location.protocol}//${window.location.host}` +
          '/auth/callback',
        params: {
          scope: 'openid',
        },
        prompt: 'none',
      },
    };

    const lock = new Auth0Lock(
      $auth.strategy.options.client_id,
      $auth.strategy.options.domain,
      options
    );

    // Catch known errors to prevent sending them to Sentry
    lock.on('unrecoverable_error', err => {
      console.log(err.message);
    });
    lock.on('authorization_error', err => {
      console.log(err.message);
    });
    return lock;
  };

  // If there for some reason isn't a renewed token and we do a api request,
  // then check for 401 errors and reset auth.
  $axios.onError(error => {
    if (error.response && error.response.status === 401) {
      $auth.reset();
    }

    return Promise.reject(error);
  });

  // The checkSession method allows you to acquire a new token from Auth0
  // for a user who is already authenticated against the universal login page for your domain.
  // Uses the auth0 lock and checks the session and gets a new access-token.
  const checkSession = async () => {
    if (!$auth.loggedIn) {
      // User is not logged in. A checkSession will fail.
      console.log('User is not logged in');
      return;
    }

    const now = Math.floor(new Date().getTime() / 1000);
    const expiresAt = $auth.$storage.getUniversal('expiresAt') || now;
    const secondsLeft = expiresAt - now;
    if (secondsLeft > 3600) {
      // More than one hour left on session, no renewal needed.
      console.log('No renewal is needed');
      return;
    }

    const lock = getLock();
    lock.checkSession(
      { scope: 'openid profile email', prompt: 'none' },
      (error, authResult) => {
        if (error || !authResult) {
          // TODO How to handle this? If another failure happens e.g. 404 or 500 perhaps auth.reset is not correct.
          console.log('Unable to renew token.');
          return;
        } else {
          console.log('Token was renewed.');
          const { accessToken } = authResult;

          // Set expiresAt date to keep track of latest time we need to make a token refresh
          const expiresAt = Math.floor(new Date().getTime() / 1000) + 7200;
          $auth.$storage.setUniversal('expiresAt', expiresAt);

          $auth.setToken('auth0', 'Bearer ' + accessToken);

          if ($axios) {
            $axios.setToken(accessToken, 'Bearer');
          }
          return;
        }
      }
    );
  };

  await checkSession();
  setInterval(checkSession, 5 * 60 * 1000); // check every 5 minutes
}
