import Vue from 'vue';
import Snotify from 'vue-snotify';
import { handleGraphQLErrors } from '../utils/graphql-helper';

Vue.use(Snotify, {
  toast: {
    timeout: 5000,
    position: 'leftTop',
  },
});

Vue.prototype.$snotify.error = (function(_super) {
  return function() {
    if (arguments && arguments.length === 1) {
      return _super.apply(this, [handleGraphQLErrors(arguments[0])]);
    }

    return _super.apply(this, arguments);
  };
})(Vue.prototype.$snotify.error);
