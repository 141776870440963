const initialState = () => {
  const isOpen =
    window.localStorage.getItem('sidebar-minimized') + '' === 'true'
      ? false
      : true;
  if (isOpen) {
    document.body.classList.add('body-pd');
  } else {
    document.body.classList.remove('body-pd');
  }
  return {
    isOpen:
      window.localStorage.getItem('sidebar-minimized') + '' === 'true'
        ? false
        : true,
  };
};

export const state = initialState();

export const getters = {
  isMenuOpen: state => {
    return state.isOpen;
  },
};

export const actions = {
  resetState({ commit }) {
    commit('resetState');
  },
  updateIsMenuOpen({ commit, state }) {
    if (state.isOpen) {
      commit('updateIsMenuOpen', false);
      document.body.classList.remove('body-pd');
    } else {
      commit('updateIsMenuOpen', true);
      document.body.classList.add('body-pd');
    }
    window.localStorage.setItem('sidebar-minimized', !state.isOpen);
  },
};

export const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  updateIsMenuOpen(state, isOpen) {
    state.isOpen = isOpen;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
