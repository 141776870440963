import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0eddc8a5 = () => interopDefault(import('../../src/marketplace/pages/approvals/index.vue' /* webpackChunkName: "pages/approvals/index" */))
const _96914890 = () => interopDefault(import('../../src/marketplace/pages/campaigns/index.vue' /* webpackChunkName: "pages/campaigns/index" */))
const _3a02340f = () => interopDefault(import('../../src/marketplace/pages/service-desk.vue' /* webpackChunkName: "pages/service-desk" */))
const _1992d221 = () => interopDefault(import('../../src/marketplace/pages/sites/index.vue' /* webpackChunkName: "pages/sites/index" */))
const _35bad6de = () => interopDefault(import('../../src/marketplace/pages/auth/callback.vue' /* webpackChunkName: "pages/auth/callback" */))
const _23d2e2c0 = () => interopDefault(import('../../src/marketplace/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _09e86606 = () => interopDefault(import('../../src/marketplace/pages/campaigns/create.vue' /* webpackChunkName: "pages/campaigns/create" */))
const _e69aca5a = () => interopDefault(import('../../src/marketplace/pages/campaigns/_campaignId/index.vue' /* webpackChunkName: "pages/campaigns/_campaignId/index" */))
const _075e17fe = () => interopDefault(import('../../src/marketplace/pages/sites/_token/index.vue' /* webpackChunkName: "pages/sites/_token/index" */))
const _1b316219 = () => interopDefault(import('../../src/marketplace/pages/campaigns/_campaignId/edit.vue' /* webpackChunkName: "pages/campaigns/_campaignId/edit" */))
const _3c27c3c2 = () => interopDefault(import('../../src/marketplace/pages/campaigns/_campaignId/ad-sets/create.vue' /* webpackChunkName: "pages/campaigns/_campaignId/ad-sets/create" */))
const _dfb5173e = () => interopDefault(import('../../src/marketplace/pages/campaigns/_campaignId/ad-sets/_adSetId/index.vue' /* webpackChunkName: "pages/campaigns/_campaignId/ad-sets/_adSetId/index" */))
const _7d4cefef = () => interopDefault(import('../../src/marketplace/pages/campaigns/_campaignId/ad-sets/_adSetId/new.vue' /* webpackChunkName: "pages/campaigns/_campaignId/ad-sets/_adSetId/new" */))
const _2b7325e4 = () => interopDefault(import('../../src/marketplace/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/approvals",
    component: _0eddc8a5,
    name: "approvals"
  }, {
    path: "/campaigns",
    component: _96914890,
    name: "campaigns"
  }, {
    path: "/service-desk",
    component: _3a02340f,
    name: "service-desk"
  }, {
    path: "/sites",
    component: _1992d221,
    name: "sites"
  }, {
    path: "/auth/callback",
    component: _35bad6de,
    name: "auth-callback"
  }, {
    path: "/auth/login",
    component: _23d2e2c0,
    name: "auth-login"
  }, {
    path: "/campaigns/create",
    component: _09e86606,
    name: "campaigns-create"
  }, {
    path: "/campaigns/:campaignId",
    component: _e69aca5a,
    name: "campaigns-campaignId"
  }, {
    path: "/sites/:token",
    component: _075e17fe,
    name: "sites-token"
  }, {
    path: "/campaigns/:campaignId/edit",
    component: _1b316219,
    name: "campaigns-campaignId-edit"
  }, {
    path: "/campaigns/:campaignId/ad-sets/create",
    component: _3c27c3c2,
    name: "campaigns-campaignId-ad-sets-create"
  }, {
    path: "/campaigns/:campaignId/ad-sets/:adSetId",
    component: _dfb5173e,
    name: "campaigns-campaignId-ad-sets-adSetId"
  }, {
    path: "/campaigns/:campaignId/ad-sets/:adSetId?/new",
    component: _7d4cefef,
    name: "campaigns-campaignId-ad-sets-adSetId-new"
  }, {
    path: "/",
    component: _2b7325e4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
