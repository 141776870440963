
import Vue from 'vue';

interface IMessage {
  message: string;
  status: string;
  isActive: boolean;
  services: string[];
}

interface IData {
  messages: IMessage[];
}

const appName = process.env.app || null;

export default Vue.extend({
  data(): IData {
    return {
      messages: [],
    };
  },
  mounted() {
    this.getMessages();
    setInterval(this.getMessages, 1 * 60 * 1000);
  },
  methods: {
    async getMessages() {
      const response = await this.$axios.$get(
        `https://assets.strossle.com/status.json?cachebuster=${Math.random()}`
      );
      if (Array.isArray(response)) {
        this.messages = response
          .filter(
            i =>
              Array.isArray(i.services) &&
              (i.services.length === 0 || i.services.includes(appName))
          )
          .map(i => {
            const m: IMessage = {
              message: i.message,
              status: i.status,
              isActive: i.is_active,
              services: i.services,
            };
            return m;
          });
      }
    },
  },
});
