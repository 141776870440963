<template>
  <span @click="open">
    <fa-icon
      :icon="['fas', 'circle-question']"
      size="lg"
      :style="{
        color: '#4d92e3',
        'font-size': '1.75em',
        margin: '3px 10px 0 0',
        cursor: 'pointer',
      }"
    />
  </span>
</template>
<script>
import { mapState } from 'vuex';

// Scoped user for contact form prefill
const user = {
  name: '',
  email: '',
};

// Lighthouse widget, only in Marketplace for now
// Will place widget as soon as this file is imported
window.hdlh = {
  widget_key: '875rhyhoh7z4x2q47c8r',
  color_mode: 'light',
  brand: 'Help',
  frame_width: '400px',
  position: 'bottom right',
  article_link_target: '_blank',
  logo: 'https://cdn.helpdocs.io/img/logo_white.svg',
  launcher_button_image: 'https://cdn.helpdocs.io/img/logo_white.svg',
  i18n: {
    contact_button: 'Contact',
    contact_name_placeholder: 'Full name',
    contact_email_placeholder: 'email@example.com',
    contact_message_placeholder: 'I need help with...',
    contact_submit: 'Send',
    search_placeholder: 'Find articles...',
    view_all: 'View All',
    suggested_articles: 'Suggested Articles',
  },
  onShow() {},
  onHide() {
    // Hide the icon we use our own in a custom place
    window.Lighthouse.hideButton();
  },
  onReady() {
    // Hide the icon we use our own in a custom place
    window.Lighthouse.hideButton();
  },
  user,
};
(function(h, d) {
  var s = d.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = h + '?t=' + new Date().getTime();
  d.head.appendChild(s);
})('https://lighthouse.helpdocs.io/load', document);

// For the contact prefill feature to work, you have to put the <lighthouse-widget />
// tag in the parent template
export default {
  computed: {
    ...mapState('auth', {
      profile: state => state.user,
    }),
  },
  methods: {
    open(event) {
      event.preventDefault();
      window.Lighthouse.showButton();
      window.Lighthouse.show();
      if (process.env.lighthouseCategory) {
        window.Lighthouse.navigate('category', process.env.lighthouseCategory);
      }
    },
  },
  watch: {
    profile: {
      handler(val) {
        if (val) {
          user.name = val.name ? val.name : '';
          user.email = val.email ? val.email : '';
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
