/*
 Ad Events
 */
export const AD_CREATED = 'AD_CREATED';
export const AD_UPDATED = 'AD_UPDATED';
export const CLICK_URL_UPDATED = 'CLICK_URL_UPDATED';
export const AD_OPTIMIZATION_CLICKED = 'AD_OPTIMIZATION_CLICKED';
export const REJECTED_AD_UPDATED = 'REJECTED_AD_UPDATED';

/*
 Ad Set Events
 */
export const AD_SET_CREATED = 'AD_SET_CREATED';
export const AD_SET_UPDATED = 'AD_SET_UPDATED';
export const AD_SET_ARCHIVED = 'AD_SET_ARCHIVED';
export const AD_SET_RESTORED = 'AD_SET_RESTORED';
export const GEOLOCATION_SELECTOR_CLICKED = 'GEOLOCATION_SELECTOR_CLICKED';

/*
 Campaign Events
 */
export const CAMPAIGN_CREATED = 'CAMPAIGN_CREATED';
export const CAMPAIGN_UPDATED = 'CAMPAIGN_UPDATED';
export const CAMPAIGN_ARCHIVED = 'CAMPAIGN_ARCHIVED';
export const CAMPAIGN_RESTORED = 'CAMPAIGN_RESTORED';
