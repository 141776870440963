interface IFlash {
  title?: string;
  message: string;
  type: string;
  options?: any;
}

interface IState {
  flash: IFlash | null;
}

export const state = (): IState => {
  return {
    flash: null,
  };
};

export const mutations = {
  SET_FLASH: (state: IState, { message, title, options, type }) => {
    state.flash = {
      title,
      message,
      type,
      options,
    };
  },
  FLUSH_FLASH: (state: IState) => {
    state.flash = null;
  },
};

export const actions = {
  setFlash: ({ commit }, { message, title, type, options }) =>
    commit('SET_FLASH', { message, title, type, options }),
};
