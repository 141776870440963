import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDate', (value: string) => {
  if (value) {
    return moment(String(value)).format('YYYY/MM/DD');
  }
});

Vue.filter('capitalizeFirst', (val: string, splitOn?: string) => {
  if (typeof val !== 'string' || !val) {
    return '';
  }
  const capitalizeFirst = (s: string) => {
    const lower = s.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  };

  if (splitOn) {
    return val.split(splitOn).map(capitalizeFirst).join(' ');
  }

  return capitalizeFirst(val);
});

Vue.filter('round', (value, decimals: number) => {
  if (!value) {
    value = 0;
  }
  if (!decimals) {
    decimals = 0;
  }
  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
});

Vue.filter('thousandsFormatter', (num: string | number): string => {
  if (num === null || num === undefined || num === '') {
    return '';
  }
  const isFloat = (n) => Number(n) === n && n % 1 !== 0;

  const numStr = isFloat(num)
    ? Number.parseFloat(String(num)).toFixed(2)
    : num.toString();

  const splits = numStr.split('.');
  let ints: string = splits[0];
  const decimals = splits.length > 1 ? splits[1] : null;

  const res: string[] = [];
  if (ints) {
    ints = ints.replace(/\D/g, '');
    res.push(ints.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '));
  }
  if (decimals) {
    res.push(`.${decimals}`);
  }
  return res.join('');
});

Vue.filter('twoDecimalsFormatter', (num: number): string => {
  if (num === null || num === undefined || isNaN(num)) {
    num = 0;
  }
  return (+num).toFixed(2);
});

Vue.filter('ctr', (clicks: number, impressions: number): string | null => {
  if (impressions === 0 || clicks === 0) {
    return null;
  }
  return ((clicks / impressions) * 100).toFixed(2);
});

Vue.filter('append', (str: string, append: string): string => {
  return [str, append].join('');
});

Vue.filter('prepend', (str: string, prepend: string): string => {
  return [prepend, str].join('');
});

Vue.filter(
  'imageResize',
  (value: string, width: number, height: number, dpr: number = 2) => {
    if (!value) {
      return;
    }
    width = width ? width : 0;
    height = height ? height : 0;
    return `https://images.sprinklecontent.com/${width}/${height}/?i=${encodeURIComponent(
      value
    )}&dpr=${dpr}`;
  }
);

Vue.filter('avgTimespentFormatter', (value) => {
  if (!value) {
    return '-';
  }
  return [
    parseInt(String(value / 60 / 60), 0),
    parseInt(String((value / 60) % 60), 0),
    parseInt(String(value % 60), 0),
  ]
    .join(':')
    .replace(/\b(\d)\b/g, '0$1');
});
