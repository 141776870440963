import Vue from 'vue';
import gql from 'graphql-tag';
import * as fragments from '../gql/fragments';
import * as api from '~/shared/types/api';
import { ActionTree, MutationTree, ActionContext } from 'vuex';
import { DocumentNode } from 'graphql';
import moment from 'moment';
import { deepEqual } from 'fast-equals';
import {
  AD_CREATED,
  AD_UPDATED,
  CLICK_URL_UPDATED,
  AD_SET_CREATED,
  AD_SET_UPDATED,
  AD_SET_ARCHIVED,
  AD_SET_RESTORED,
  CAMPAIGN_CREATED,
  CAMPAIGN_UPDATED,
  CAMPAIGN_ARCHIVED,
  CAMPAIGN_RESTORED,
  REJECTED_AD_UPDATED,
} from '~shared/utils/events';

interface INameID {
  id: number;
  name: string;
}

export interface IGeoLocationIds {
  includeCountryIds: string[];
  includeStateIds: string[];
  includeCityIds: string[];
  excludeCountryIds: string[];
  excludeStateIds: string[];
  excludeCityIds: string[];
}

export interface IDuplicate {
  campaignId: string | null;
  adSetId: string | null;
  name: string;
  saving: boolean;
  provider: string | null;
}

export interface ISorting {
  column: null | string;
  direction: null | string;
}

export interface IUser {
  id: string;
  email: string;
}

export interface ICampaign
  extends Pick<
    api.Campaign,
    | 'id'
    | 'name'
    | 'adSets'
    | 'currency'
    | 'createdAt'
    | 'isArchived'
    | 'provider'
  > {
  advertiser: Pick<
    api.Group,
    'id' | 'name' | 'language' | 'currency' | 'reportSettings'
  >;
  isHouseAdCampaign: boolean | null;
  statistics: Pick<
    api.Statistics,
    'clicks' | 'impressions' | 'spent' | 'lastUpdated'
  >;
  currency: string;
  createdAt: Date | null;
  status: string;
  isArchived: boolean;
  tradedBy: IUser | null;
  traffickedBy: IUser | null;
  numberOfArchivedAdSets: number;
}

interface IExchangeRate {
  currency: string;
  rate: number;
}

export interface ITarget
  extends Pick<api.Target, 'includedGeolocations' | 'excludedGeolocations'> {
  platforms: string[];
  categories: string[];
}

export interface IAdSet
  extends Omit<
    api.AdSet,
    | 'rateType'
    | 'target'
    | 'rate'
    | 'widgetIds'
    | 'widgetGroupIds'
    | 'deliveryMode'
    | 'adsOptimization'
    | 'properties'
  > {
  sitePacks: any[];
  widgetIds: string[];
  widgetGroupIds: string[];
  rateType: string;
  rate: number | null;
  target: ITarget;
  statistics: Pick<
    api.Statistics,
    'clicks' | 'impressions' | 'spent' | 'lastUpdated'
  >;
  deliveryMode: string;
  adsOptimization: string;
  properties: Pick<
    api.AdSetV1Properties,
    'stopAtEndDate' | 'maxImpressions' | 'maxClicks' | 'priorityId'
  >;
}

export interface IAd {
  id: string | number | null;
  campaignId: string | number | null;
  adSetId: string | number | null;
  imageUrl: string | null;
  title: string | null;
  content: string | null;
  brandName: string | null;
  iabCategory: string | null;
  advertiserDomain: string | null;
  clickUrl: string | null;
  impressionPixels: string[];
  imageOverlayText: string | null;
  isActivated: boolean;
  hasPlayButton: boolean;
  type: string;
  name: string;
  status: string | null;
  statusMessage: string | null;
  externalLink: boolean;
  retouchMark: string | null;
  statistics: Pick<
    api.Statistics,
    'clicks' | 'impressions' | 'spent' | 'lastUpdated'
  >;
}

interface IPermissions {
  id: string | null;
  permissions: string[];
}

export interface IState {
  campaign: ICampaign;
  adSet: IAdSet;
  initialAdSet: IAdSet;
  adSetAdCount: number;
  adSetSitePackSet: boolean;
  ad: IAd;
  initialAd: IAd;
  exchangeRate: IExchangeRate;
  permissions: IPermissions;
}

// This is languages that will activate the category selection.
// Only languages that are active in Category Extraction should be here.
// SK removed see ST-2404
const validCategoryLanguages = ['SV', 'NO', 'FI', 'DA', 'NL', 'CS'];

const initialState: IState = {
  /*
   * The entities that we are currently working on
   * These entities will be updated as the user makes
   * changes to them in the UI.
   */
  campaign: {
    id: '',
    name: '',
    isHouseAdCampaign: null,
    currency: '',
    createdAt: null,
    statistics: {
      clicks: 0,
      impressions: 0,
      spent: 0,
      lastUpdated: null,
    },
    advertiser: {
      id: '',
      name: '',
      language: '',
      currency: '',
    },
    status: '',
    isArchived: false,
    tradedBy: null,
    traffickedBy: null,
    numberOfArchivedAdSets: 0,
  },
  adSet: {
    campaignId: '',
    id: '',
    name: '',
    startDate: null,
    createdAt: null,
    endDate: null,
    properties: {
      stopAtEndDate: true,
      maxImpressions: 0,
      maxClicks: 0,
      priorityId: 50,
    },
    rateType: 'CPC',
    rate: null,
    sitePacks: [],
    widgetIds: [],
    widgetGroupIds: [],
    status: null,
    statistics: {
      clicks: 0,
      impressions: 0,
      spent: 0,
      lastUpdated: null,
    },
    target: {
      includedGeolocations: [],
      excludedGeolocations: [],
      platforms: [],
      categories: [],
    },
    deliveryMode: 'BALANCED',
    adsOptimization: 'EVEN',
  },
  initialAdSet: {
    campaignId: '',
    id: '',
    name: '',
    startDate: null,
    createdAt: null,
    endDate: null,
    properties: {
      stopAtEndDate: true,
      maxImpressions: 0,
      maxClicks: 0,
      priorityId: 50,
    },
    rateType: 'CPC',
    rate: null,
    sitePacks: [],
    widgetIds: [],
    widgetGroupIds: [],
    status: null,
    statistics: {
      clicks: 0,
      impressions: 0,
      spent: 0,
      lastUpdated: null,
    },
    target: {
      includedGeolocations: [],
      excludedGeolocations: [],
      platforms: [],
      categories: [],
    },
    deliveryMode: 'BALANCED',
    adsOptimization: 'EVEN',
  },
  adSetAdCount: 0,
  adSetSitePackSet: false,
  ad: {
    id: null,
    campaignId: null,
    adSetId: null,
    imageUrl: null,
    title: null,
    content: null,
    brandName: null,
    iabCategory: null,
    advertiserDomain: null,
    clickUrl: null,
    impressionPixels: [''],
    imageOverlayText: null,
    isActivated: false,
    hasPlayButton: false,
    type: 'LINK',
    name: '',
    status: null,
    statusMessage: null,
    externalLink: false,
    retouchMark: null,
    statistics: {
      clicks: 0,
      impressions: 0,
      spent: 0,
      lastUpdated: null,
    },
  },
  initialAd: {
    id: null,
    campaignId: null,
    adSetId: null,
    imageUrl: null,
    title: null,
    content: null,
    brandName: null,
    iabCategory: null,
    advertiserDomain: null,
    clickUrl: null,
    impressionPixels: [''],
    imageOverlayText: null,
    isActivated: false,
    hasPlayButton: false,
    type: 'LINK',
    name: '',
    status: null,
    statusMessage: null,
    externalLink: false,
    retouchMark: null,
    statistics: {
      clicks: 0,
      impressions: 0,
      spent: 0,
      lastUpdated: null,
    },
  },
  exchangeRate: {
    currency: 'EUR',
    rate: 1,
  },
  permissions: {
    id: null,
    permissions: [],
  },
};

export const getInitialState = () => {
  return JSON.parse(JSON.stringify(initialState));
};

export const state = () => {
  return {
    ...(Vue as any).util.extend({}, getInitialState()),
  };
};

/********************
 * Ad               *
 ********************/

const adMutations: MutationTree<IState> = {
  FLUSH_AD: (state: IState) => {
    Object.assign(state.ad, JSON.parse(JSON.stringify(initialState.ad)));
    Object.assign(state.initialAd, JSON.parse(JSON.stringify(initialState.ad)));
  },
  SET_AD: (state: IState, ad: any) => {
    Object.assign(state.ad, ad);
    Object.assign(state.initialAd, JSON.parse(JSON.stringify(ad)));
  },
  SET_IMAGE_URL: (state: IState, imageUrl: string) =>
    (state.ad.imageUrl = imageUrl),
  SET_TITLE: (state: IState, title: string) => (state.ad.title = title),
  SET_CONTENT: (state: IState, content: string) => (state.ad.content = content),
  SET_BRAND_NAME: (state: IState, brandName: string) =>
    (state.ad.brandName = brandName),
  SET_IAB_CATEGORY: (state: IState, iabCategory: string) =>
    (state.ad.iabCategory = iabCategory),
  SET_ADVERTISER_DOMAIN: (state: IState, advertiserDomain: string) =>
    (state.ad.advertiserDomain = advertiserDomain),
  SET_CLICK_URL: (state: IState, clickUrl: string) =>
    (state.ad.clickUrl = clickUrl),
  SET_IMPRESSION_PIXELS: (state: IState, { val, index }) => {
    // Need to use splice in order to be deeply reactive
    state.ad.impressionPixels.splice(index, 1, val);
  },
  ADD_IMPRESSION_PIXEL: (state: IState) => state.ad.impressionPixels.push(''),
  SET_IMAGE_OVERLAY_TEXT: (state: IState, imageOverlayText: string) =>
    (state.ad.imageOverlayText = imageOverlayText),
  SET_HAS_PLAY_BUTTON: (state: IState, hasPlayButton: boolean) =>
    (state.ad.hasPlayButton = hasPlayButton),
  SET_TYPE: (state: IState, type: string) => (state.ad.type = type),
  SET_AD_NAME: (state: IState, name: string) => (state.ad.name = name),
  SET_EXTERNAL_LINK: (state: IState, externalLink: boolean) =>
    (state.ad.externalLink = externalLink),
  SET_RETOUCH_MARK: (state: IState, retouchMark: string | null) =>
    (state.ad.retouchMark = retouchMark),
};

const adGetters = {
  adChanged: (state: IState): boolean => {
    return !deepEqual(state.ad, state.initialAd);
  },
  impressionPixels: (state: IState): string[] => {
    return state.ad.impressionPixels;
  },
  showRetouchMark: (state: IState): boolean => {
    if (
      state.adSet.sitePacks.length > 0 &&
      state.adSet.sitePacks[0].language === 'NO'
    ) {
      return true;
    }
    return false;
  },
};

const adActions: ActionTree<IState, IState> = {
  flushAd: ({ commit }) => commit('FLUSH_AD'),
  setImageUrl: ({ commit }, val: string) => {
    commit('SET_IMAGE_URL', val);
  },
  setTitle: ({ commit }, val: string) => {
    commit('SET_TITLE', val);
  },
  setContent: ({ commit }, val: string) => {
    commit('SET_CONTENT', val);
  },
  setBrandName: ({ commit }, val: string) => {
    commit('SET_BRAND_NAME', val);
  },
  setIabCategory: ({ commit }, val: string) => {
    commit('SET_IAB_CATEGORY', val);
  },
  setAdvertiserDomain: ({ commit }, val: string) => {
    commit('SET_ADVERTISER_DOMAIN', val);
  },
  setClickUrl: ({ commit }, val: string) => {
    Vue.prototype.$featureAnalytics.send(CLICK_URL_UPDATED);
    commit('SET_CLICK_URL', val);
  },
  setImpressionPixels: ({ commit }, val: { val: string; index: number }) => {
    commit('SET_IMPRESSION_PIXELS', val);
  },
  addImpressionPixel: ({ commit }) => {
    commit('ADD_IMPRESSION_PIXEL');
  },
  setImageOverlayText: ({ commit }, val: string) => {
    commit('SET_IMAGE_OVERLAY_TEXT', val);
  },
  async saveAd({ state }: ActionContext<IState, IState>) {
    const campaignId = state.campaign.id;
    const adSetId = state.adSet.id;
    if (!campaignId || !adSetId) {
      throw new Error('CampaignId and adSetId must be set');
    }
    if (
      state.ad.id &&
      (campaignId !== state.ad.campaignId || adSetId !== state.ad.adSetId)
    ) {
      throw new Error('CampaignId and adSetId must match');
    }
    const impressionPixels = state.ad.impressionPixels.filter((i) => i);
    let mutation: DocumentNode;
    let variables = {};
    if (!state.ad.id) {
      mutation = gql`
        mutation ($adSetId: ID!, $campaignId: ID!, $input: AdInput!) {
          createAd(adSetId: $adSetId, campaignId: $campaignId, input: $input) {
            id
          }
        }
      `;
      variables = {
        adSetId,
        campaignId,
        input: {
          title: state.ad.title,
          brandName: state.ad.brandName,
          clickUrl: state.ad.clickUrl,
          imageUrl: state.ad.imageUrl,
          content: state.ad.content || '',
          imageOverlayText: state.ad.imageOverlayText,
          impressionPixels,
          hasPlayButton: state.ad.hasPlayButton,
          type: state.ad.type,
          name: state.ad.name,
          externalLink: state.ad.externalLink,
          retouchMark: state.ad.retouchMark,
        },
      };
    } else {
      mutation = gql`
        mutation ($id: ID!, $adSetId: ID!, $campaignId: ID!, $input: AdInput!) {
          updateAd(
            id: $id
            adSetId: $adSetId
            campaignId: $campaignId
            input: $input
          ) {
            id
            title
            brandName
            clickUrl
            imageUrl
            content
            imageOverlayText
            impressionPixels
            hasPlayButton
            externalLink
            retouchMark
          }
        }
      `;
      variables = {
        id: state.ad.id,
        adSetId,
        campaignId,
        input: {
          title: state.ad.title,
          brandName: state.ad.brandName,
          clickUrl: state.ad.clickUrl,
          imageUrl: state.ad.imageUrl,
          content: state.ad.content || '',
          imageOverlayText: state.ad.imageOverlayText,
          impressionPixels,
          hasPlayButton: state.ad.hasPlayButton,
          type: state.ad.type,
          name: state.ad.name,
          externalLink: state.ad.externalLink,
          retouchMark: state.ad.retouchMark,
        },
      };
    }

    if (this.app.apolloProvider) {
      await this.app.apolloProvider.defaultClient.mutate({
        mutation,
        variables,
      });
      if (!state.ad.id) {
        Vue.prototype.$featureAnalytics.send(AD_CREATED);
      } else {
        Vue.prototype.$featureAnalytics.send(AD_UPDATED);
      }
      if (
        state.ad.status === 'REJECTED_REVIEW_WILL_BE_ACTIVE' ||
        state.ad.status === 'REJECTED_REVIEW_WILL_BE_INACTIVE'
      ) {
        Vue.prototype.$featureAnalytics.send(REJECTED_AD_UPDATED);
      }
    }
  },
  async fetchAd({ commit }: ActionContext<IState, IState>, { id, resetId }) {
    const query = gql`
      query ($adId: ID!) {
        Ad(id: $adId) {
          id
          campaignId
          adSetId
          title
          content
          brandName
          imageOverlayText
          impressionPixels
          imageUrl
          clickUrl
          hasPlayButton
          type
          name
          status
          statusMessage
          externalLink
          retouchMark
        }
      }
    `;
    const variables = { adId: id };
    if (this.app.apolloProvider) {
      const result = await this.app.apolloProvider.defaultClient.query({
        query,
        variables,
        fetchPolicy: 'no-cache',
      });
      if (result && result.data && result.data.Ad && result.data.Ad.id) {
        if (resetId) {
          result.data.Ad.id = null;
        }
        commit('SET_AD', result.data.Ad);
        return result.data.Ad;
      }
    }
  },
};

/********************
 * Ad Set           *
 ********************/

const adSetMutations: MutationTree<IState> = {
  FLUSH_AD_SET: (state: IState) => {
    state.adSetAdCount = 0;
    state.adSetSitePackSet = false;
    state.adSet = JSON.parse(JSON.stringify(initialState.adSet));
    state.initialAdSet = JSON.parse(JSON.stringify(initialState.adSet));
  },
  SET_AD_SET: (state: IState, adSet: any) => {
    state.adSetSitePackSet = true;
    state.adSet = JSON.parse(JSON.stringify(adSet));
    state.initialAdSet = JSON.parse(JSON.stringify(adSet));
  },
  SET_AD_SET_RATE: (state: IState, val: number) => (state.adSet.rate = val),
  SET_AD_SET_NAME: (state: IState, name: string) => (state.adSet.name = name),
  SET_START_DATE: (state: IState, date: Date | null) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      state.adSet.startDate = date.toJSON();
    } else if (
      date instanceof Date &&
      moment(date, moment.ISO_8601, true).isValid()
    ) {
      state.adSet.startDate = date;
    } else {
      state.adSet.startDate = null;
    }
  },
  SET_END_DATE: (state: IState, date: Date | null) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      state.adSet.endDate = date.toJSON();
    } else if (
      date instanceof Date &&
      moment(date, moment.ISO_8601, true).isValid()
    ) {
      state.adSet.endDate = date;
    } else {
      state.adSet.endDate = null;
    }
  },
  SET_STOP_AT_END_DATE: (state: IState, stopAtEndDate: boolean) =>
    (state.adSet.properties.stopAtEndDate = stopAtEndDate),
  SET_RATE_TYPE: (state: IState, rateType: api.RateType) =>
    (state.adSet.rateType = rateType),
  SET_MAX_IMPRESSIONS: (state: IState, val: number) =>
    (state.adSet.properties.maxImpressions = val),
  SET_MAX_CLICKS: (state: IState, val: number) =>
    (state.adSet.properties.maxClicks = val),
  SET_PLATFORMS: (state: IState, platforms: api.Platform[]) =>
    (state.adSet.target.platforms = platforms),
  ADD_INCLUDE_GEOLOCATION: (state: IState, geolocation: api.Geolocation) => {
    state.adSet.target.includedGeolocations = (
      state.adSet.target.includedGeolocations || []
    ).concat(geolocation);
  },
  ADD_EXCLUDE_GEOLOCATION: (state: IState, geolocation: api.Geolocation) => {
    state.adSet.target.excludedGeolocations = (
      state.adSet.target.excludedGeolocations || []
    ).concat(geolocation);
  },
  SET_INCLUDED_GEOLOCATIONS: (
    state: IState,
    geolocations: api.Geolocation[]
  ) => {
    state.adSet.target.includedGeolocations = geolocations;
  },
  SET_EXCLUDED_GEOLOCATIONS: (
    state: IState,
    geolocations: api.Geolocation[]
  ) => {
    state.adSet.target.excludedGeolocations = geolocations;
  },
  REMOVE_INCLUDE_GEOLOCATION: (state: IState, geolocation: api.Geolocation) => {
    const json = JSON.stringify(geolocation);
    state.adSet.target.includedGeolocations = (
      state.adSet.target.includedGeolocations || []
    ).filter((location: api.Geolocation) => {
      return json !== JSON.stringify(location);
    });
  },
  REMOVE_EXCLUDE_GEOLOCATION: (state: IState, geolocation: api.Geolocation) => {
    const json = JSON.stringify(geolocation);
    state.adSet.target.excludedGeolocations = (
      state.adSet.target.excludedGeolocations || []
    ).filter((location: api.Geolocation) => {
      return json !== JSON.stringify(location);
    });
  },
  SET_SITE_PACKS: (state: IState, sitePacks: api.SitePack[]) => {
    state.adSet.sitePacks = Array.isArray(sitePacks) ? sitePacks : [sitePacks];
    if (!state.adSetSitePackSet) {
      state.initialAdSet.sitePacks = Array.isArray(sitePacks)
        ? sitePacks
        : [sitePacks];
      state.adSetSitePackSet = true;
    }
  },
  FLUSH_SITE_PACKS: (state: IState) => {
    state.adSet.sitePacks = getInitialState().adSet.sitePacks;
  },
  SET_AD_SET_AD_COUNT: (state: IState, adCount: number) => {
    state.adSetAdCount = adCount;
  },
  SET_AD_SET_CATEGORIES: (state: IState, categories: string[]) => {
    state.adSet.target.categories = categories;
  },
  SET_AD_SET_WIDGET_IDS: (state: IState, widgetIds: string[]) => {
    state.adSet.widgetIds = widgetIds;
  },
  SET_AD_SET_WIDGET_GROUP_IDS: (state: IState, widgetGroupIds: string[]) => {
    state.adSet.widgetGroupIds = widgetGroupIds;
  },
  SET_EXCHANGE_RATE: (state: IState, rate: IExchangeRate) => {
    state.exchangeRate = rate;
  },
  SET_DELIVERY_MODE: (state: IState, mode: api.DeliveryMode) => {
    state.adSet.deliveryMode = mode;
  },
  SET_PRIORITY_ID: (state: IState, priority: number) => {
    state.adSet.properties.priorityId = priority;
  },
  SET_ADS_OPTIMIZATION: (state: IState, optimization: string) => {
    state.adSet.adsOptimization = optimization;
  },
};
const adSetGetters = {
  geoLocationIds: (state: IState): IGeoLocationIds => {
    const ids: IGeoLocationIds = {
      includeCityIds: [],
      includeStateIds: [],
      includeCountryIds: [],
      excludeCityIds: [],
      excludeStateIds: [],
      excludeCountryIds: [],
    };
    if (state.adSet.target.includedGeolocations) {
      state.adSet.target.includedGeolocations.forEach((location) => {
        switch (location.type) {
          case 'CITY':
            if (location.city) {
              ids.includeCityIds.push(location.city.id);
            }
            break;
          case 'STATE':
            if (location.state) {
              ids.includeStateIds.push(location.state.id);
            }
            break;
          case 'COUNTRY':
            if (location.country) {
              ids.includeCountryIds.push(location.country.id);
            }
        }
      });
    }
    if (state.adSet.target.excludedGeolocations) {
      state.adSet.target.excludedGeolocations.forEach((location) => {
        switch (location.type) {
          case 'CITY':
            if (location.city) {
              ids.excludeCityIds.push(location.city.id);
            }
            break;
          case 'STATE':
            if (location.state) {
              ids.excludeStateIds.push(location.state.id);
            }
            break;
          case 'COUNTRY':
            if (location.country) {
              ids.excludeCountryIds.push(location.country.id);
            }
        }
      });
    }
    return ids;
  },
  budget: (state: IState, getters): number | null => {
    // Total budget =
    // Spent + ((target clicks - delivered clicks) * cpc price)
    // + ((target impressions - delivered impressions) * (cpm price / 1000))

    const spentUntilNow = (state.adSet.statistics || {}).spent || 0;

    let additionalEstimatedSpend = 0;
    if (state.adSet.rateType === 'CPC') {
      const volumeLeft =
        (getters.volume || 0) - ((state.adSet.statistics || {}).clicks || 0);
      additionalEstimatedSpend = volumeLeft * (state.adSet.rate || 0);
    } else if (state.adSet.rateType === 'CPM') {
      const volumeLeft =
        (getters.volume || 0) -
        ((state.adSet.statistics || {}).impressions || 0);
      additionalEstimatedSpend = volumeLeft * (state.adSet.rate || 0);
      if (additionalEstimatedSpend > 0) {
        additionalEstimatedSpend = additionalEstimatedSpend / 1000;
      }
    }

    const totalEstimatedSpend = spentUntilNow + additionalEstimatedSpend;

    return Math.max(spentUntilNow, totalEstimatedSpend);
  },
  budgetLeft: (state: IState, getters): number | null => {
    return Math.max(
      0,
      getters.budget - ((state.adSet.statistics || {}).spent || 0)
    );
  },
  volume: (state: IState): number | null | undefined => {
    switch (state.adSet.rateType) {
      case 'CPC':
        return state.adSet.properties.maxClicks;
      case 'CPM':
        return state.adSet.properties.maxImpressions;
      default:
        return null;
    }
  },
  /**
   * Get default language for the current selected advertiser
   */
  advertiserLanguage: (state: IState): string | null => {
    if (state.campaign && state.campaign.advertiser) {
      return state.campaign.advertiser.language || '';
    }
    return null;
  },

  /**
   * Return current language (ISO) based on selected site packs
   */
  languageBySitePacks: (state: IState): string | null => {
    const languageSitePacks = (state.adSet.sitePacks || []).filter(
      ({ type }) => type === 'LANGUAGE'
    );
    if (languageSitePacks && languageSitePacks.length) {
      return languageSitePacks[0].language;
    }

    const categorySitePacks = (state.adSet.sitePacks || []).filter(
      ({ type }) => type === 'CATEGORY'
    );
    if (categorySitePacks && categorySitePacks.length) {
      return categorySitePacks[0].language;
    }
    return null;
  },

  /**
   * Return the current selected Language SitePack if any, else null.
   */
  languageSitePack: (state: IState): api.SitePack | null => {
    const languageSitePacks = (state.adSet.sitePacks || []).filter(
      ({ type }) => type === 'LANGUAGE'
    );
    if (languageSitePacks && languageSitePacks.length) {
      return languageSitePacks[0];
    }
    return null;
  },

  /**
   * Return the current selected Category SitePacks else empty array
   */
  categorySitePacks: (state: IState): api.SitePack[] => {
    const categorySitePacks = (state.adSet.sitePacks || []).filter(
      ({ type }) => type === 'CATEGORY'
    );
    return categorySitePacks;
  },

  priceGuidelines: (state: IState): api.SitePackPriceGuidelines => {
    if (
      state.adSet.sitePacks.length > 0 &&
      state.adSet.sitePacks[0].priceGuidelines
    ) {
      return {
        minCPM: Number(
          (
            state.adSet.sitePacks[0].priceGuidelines.minCPM *
            state.exchangeRate.rate
          ).toFixed(2)
        ),
        minCPC: Number(
          (
            state.adSet.sitePacks[0].priceGuidelines.minCPC *
            state.exchangeRate.rate
          ).toFixed(2)
        ),
        highCPM: Number(
          (
            state.adSet.sitePacks[0].priceGuidelines.highCPM *
            state.exchangeRate.rate
          ).toFixed(2)
        ),
        highCPC: Number(
          (
            state.adSet.sitePacks[0].priceGuidelines.highCPC *
            state.exchangeRate.rate
          ).toFixed(2)
        ),
        maxCPM: Number(
          (
            state.adSet.sitePacks[0].priceGuidelines.maxCPM *
            state.exchangeRate.rate
          ).toFixed(2)
        ),
        maxCPC: Number(
          (
            state.adSet.sitePacks[0].priceGuidelines.maxCPC *
            state.exchangeRate.rate
          ).toFixed(2)
        ),
      };
    }
    return {
      minCPM: 0,
      minCPC: 0,
      highCPM: 0,
      highCPC: 0,
      maxCPM: 0,
      maxCPC: 0,
    };
  },

  adSetChanged: (state: IState): boolean => {
    return !deepEqual(state.adSet, state.initialAdSet);
  },

  validCategoryLanguage: (state: IState, getters: any): boolean => {
    if (!getters.languageBySitePacks) {
      const a = state.campaign.advertiser;
      if (a.language && validCategoryLanguages.includes(a.language)) {
        return true;
      }
      return false;
    }
    if (validCategoryLanguages.includes(getters.languageBySitePacks)) {
      return true;
    }
    return false;
  },

  selectedWidgetGroups: (state: IState): string[] => {
    if (!state.campaign.isHouseAdCampaign) {
      return [];
    }

    return [...state.adSet.widgetIds, ...state.adSet.widgetGroupIds];
  },

  geolocationCountry: (state: IState, getters: any): string => {
    const language = getters.languageBySitePacks || getters.advertiserLanguage;

    return (
      {
        sv: 'Sweden',
        da: 'Denmark',
        no: 'Norway',
        fi: 'Finland',
        el: 'Greece',
        nl: 'Netherlands',
        hu: 'Hungary',
        ru: 'Russian Federation',
        cs: 'Czech Republic',
        sk: 'Slovak Republic',
        es: 'Spain',
        pl: 'Poland',
      }[language.toLowerCase()] || ''
    );
  },
  adsOptimization: (state: IState): string => {
    const opt = state.adSet.adsOptimization.replace(/_AGGRESSIVE/, '');
    return opt;
  },
  adsOptimizationLevel: (state: IState): string => {
    const opt = state.adSet.adsOptimization;
    const optsToPass = ['AGGRESSIVE'];
    const find = optsToPass.find((o) => opt.includes(o));
    return find || '';
  },
};

const adSetActions: ActionTree<IState, IState> = {
  async onlineAdSet(
    { commit }: ActionContext<IState, IState>,
    adSetId: number
  ) {
    if (this.app.apolloProvider) {
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: gql`
          mutation ($adSetId: ID!) {
            onlineAdSet(id: $adSetId) {
              id
              adSetId
              createdAt
              remainingEstimateSeconds
            }
          }
        `,
        variables: {
          adSetId,
        },
      });
      return result.data.onlineAdSet;
    }
  },
  async offlineAdSet(
    { commit }: ActionContext<IState, IState>,
    adSetId: number
  ) {
    if (this.app.apolloProvider) {
      const result = await this.app.apolloProvider.defaultClient.mutate({
        mutation: gql`
          mutation ($adSetId: ID!) {
            offlineAdSet(id: $adSetId) {
              id
              adSetId
              createdAt
              remainingEstimateSeconds
            }
          }
        `,
        variables: {
          adSetId,
        },
      });
      return result.data.offlineAdSet;
    }
  },
  async flushAdSet({ commit, state }) {
    commit('FLUSH_AD_SET');
  },
  setVolume: ({ commit, state }, volume: number | null) => {
    if (state.adSet.rateType === 'CPC') {
      commit('SET_MAX_IMPRESSIONS', 0);
      commit('SET_MAX_CLICKS', volume);
    } else if (state.adSet.rateType === 'CPM') {
      commit('SET_MAX_IMPRESSIONS', volume);
      commit('SET_MAX_CLICKS', 0);
    }
  },
  setAdSetName: ({ commit }, name: string) => {
    commit('SET_AD_SET_NAME', name);
  },
  setStartDate: ({ commit }, date: Date) => {
    commit('SET_START_DATE', date);
  },
  setEndDate: ({ commit }, date: Date) => commit('SET_END_DATE', date),
  setStopAtEndDate: ({ commit }, stopAtEndDate: boolean) =>
    commit('SET_STOP_AT_END_DATE', stopAtEndDate),
  setRate: ({ commit }, rate: number | null) => {
    commit('SET_AD_SET_RATE', rate);
  },
  setRateType: ({ commit, getters, dispatch }, rateType: string) => {
    // Move allocated volume to correct field
    const volume = getters.volume;
    commit('SET_RATE_TYPE', rateType);
    dispatch('setVolume', volume);
  },
  setLanguageSitePack: (
    { commit, state, getters },
    languageSitePack: api.SitePack
  ) => {
    const currentLanguageSitePack = getters.languageSitePack;

    if (!currentLanguageSitePack) {
      // If there are no current language site pack but we have category site packs
      // we need to check if they are of the same language as the incoming site pack
      const categorySitePack = state.adSet.sitePacks.find(
        (pack: api.SitePack) =>
          pack.type === 'CATEGORY' &&
          pack.language === languageSitePack.language
      );

      if (
        categorySitePack &&
        categorySitePack.language === languageSitePack.language
      ) {
        // Same language is chosen again, but we have categories already. Don't do anything.
        return;
      } else {
        // Another language was chosen that doesn't match our categories.
        // Lets flush and set language site pack
        commit('FLUSH_SITE_PACKS');
        commit('SET_SITE_PACKS', [languageSitePack]);
      }
    } else if (currentLanguageSitePack.language !== languageSitePack.language) {
      // If language site pack is of different language then existing packs
      // we need to flush all packs and set only this language pack
      commit('FLUSH_SITE_PACKS');
      commit('SET_SITE_PACKS', [languageSitePack]);
    }
  },
  addCategorySitePack: ({ commit, state }, sitePack: api.SitePack) => {
    // Get language site pack for validation
    const languageSitePack = state.adSet.sitePacks.find(
      (pack: api.SitePack) => pack.type === 'LANGUAGE'
    );

    if (languageSitePack && languageSitePack.language !== sitePack.language) {
      // This is bad, something is wrong.
      // Missmatch of language between languagepack and categorypack
      throw new Error('Missmatch of language between sitepacks.');
    }

    const categorySitePacks = state.adSet.sitePacks.filter(
      (pack: api.SitePack) => pack.type === 'CATEGORY'
    );

    commit('SET_SITE_PACKS', [...categorySitePacks, sitePack]);
  },
  removeCategorySitePack: ({ commit, state }, sitePack: api.SitePack) => {
    const categorySitePacksToKeep = state.adSet.sitePacks.filter(
      (pack: api.SitePack) =>
        'CATEGORY' === (pack.type || '').toUpperCase() &&
        pack.id !== sitePack.id
    );

    commit('SET_SITE_PACKS', [...categorySitePacksToKeep]);
  },
  flushCategorySitePacks: ({ commit, state }) => {
    const nonCategorySitePacks = state.adSet.sitePacks.filter(
      (pack: api.SitePack) => pack.type === 'CATEGORY'
    );
    commit('SET_SITE_PACKS', nonCategorySitePacks);
  },
  addGeolocation: ({ commit }, geolocation: api.Geolocation) => {
    if (!geolocation) {
      return;
    }
    commit('ADD_GEOLOCATION', geolocation);
    commit('ADD_INCLUDE_GEOLOCATION', geolocation);
  },
  removeGeolocation: ({ commit }, geolocation: Geolocation) => {
    if (!geolocation) {
      return;
    }
    commit('REMOVE_GEOLOCATION', geolocation);
    commit('REMOVE_INCLUDE_GEOLOCATION', geolocation);
  },
  setAdSetWidgetIds: ({ commit }, widgetIds: string[]) => {
    commit('SET_AD_SET_WIDGET_IDS', widgetIds.sort());
  },
  setAdSetWidgetGroupIds: ({ commit }, widgetGroupIds: string[]) => {
    commit('SET_AD_SET_WIDGET_GROUP_IDS', widgetGroupIds.sort());
  },
  /**
   * Set platforms, note that API uses SMARTPHONE for MOBILE
   */
  setPlatforms: ({ commit }, platforms: string[]) => {
    let containsAll = true;
    if (platforms.length) {
      for (const platform of ['DESKTOP', 'TABLET', 'SMARTPHONE']) {
        containsAll = platforms.includes(platform);
        if (!containsAll) {
          break;
        }
      }
    }

    if (containsAll) {
      commit('SET_PLATFORMS', []);
    } else {
      commit('SET_PLATFORMS', platforms);
    }
  },
  async fetchCampaignsExhangeRate({
    state,
    dispatch,
  }: ActionContext<IState, IState>) {
    await dispatch('fetchExchangeRate', {
      currency: state.campaign.currency,
      date: state.campaign.createdAt,
    });
  },
  async fetchExchangeRate(
    { commit, state }: ActionContext<IState, IState>,
    { currency, date }: { currency: string; date: Date }
  ) {
    if (currency === 'EUR') {
      return commit('SET_EXCHANGE_RATE', { rate: 1, currency: 'EUR' });
    }
    if (this.app.apolloProvider) {
      const result = await this.app.apolloProvider.defaultClient.query({
        query: gql`
          query ($currency: String!, $date: DateTime) {
            ExchangeRate(currency: $currency, date: $date) {
              currency
              averageRate
            }
          }
        `,
        variables: {
          currency,
          date,
        },
      });
      if (result && result.data && result.data.ExchangeRate) {
        commit('SET_EXCHANGE_RATE', {
          rate: result.data.ExchangeRate.averageRate,
          currency: result.data.ExchangeRate.currency,
        });
      }
    }
  },
  async fetchAdSet(
    { commit, dispatch }: ActionContext<IState, IState>,
    id: number
  ) {
    if (this.app.apolloProvider) {
      return this.app.apolloProvider.defaultClient
        .query({
          query: gql`
            query ($id: ID!) {
              AdSet(id: $id) {
                ...defaultAdSet
              }
            }
            ${fragments.AdSet.fragments.defaultAdSet}
          `,
          variables: {
            id,
          },
          fetchPolicy: 'network-only',
        })
        .then(async (result) => {
          if (!result.data) {
            return;
          }
          const adSet = result.data.AdSet;
          adSet.widgetIds = adSet.widgets.map((w: any) => w.id).sort();
          adSet.widgetGroupIds = adSet.widgetGroups
            .map((w: any) => w.groupId)
            .sort();
          commit('SET_AD_SET', adSet);
          await dispatch('fetchCampaignsExhangeRate');
          return adSet;
        });
    }
  },
  async saveAdSet({ commit, state, getters }: ActionContext<IState, IState>) {
    try {
      let mutation: DocumentNode;
      let variables = {};

      const input = {
        variableDefs: `
          $endDate: DateTime!
          $name: String!
          $platforms: [Platform!]
          $categories: [String!]
          $rate: Float
          $rateType: RateType
          $sitePackIds: [ID!]
          $startDate: DateTime!
          $widgetIds: [ID!]
          $widgetGroupIds: [ID!]
          $deliveryMode: DeliveryMode
          $adsOptimization: AdsOptimization!
          $geoTargeting: GeoTargeting
          $v1Properties: AdSetV1PropertiesInput
        `,
        args: `
          {
            endDate: $endDate
            name: $name
            rate: $rate
            rateType: $rateType
            sitePackIds: $sitePackIds
            startDate: $startDate
            widgetIds: $widgetIds
            widgetGroupIds: $widgetGroupIds
            deliveryMode: $deliveryMode
            adsOptimization: $adsOptimization
            v1Properties: $v1Properties
            target: {
              platforms: $platforms
              categories: $categories
              geoTargeting: $geoTargeting
            }
          }
        `,
        variables: {
          ...Object.keys(state.adSet).reduce((acc: any, curr: string) => {
            // Simple mapping as is
            if (
              [
                'endDate',
                'name',
                'rateType',
                'startDate',
                'endDate',
                'widgetIds',
                'widgetGroupIds',
                'deliveryMode',
                'adsOptimization',
              ].includes(curr)
            ) {
              acc[curr] = state.adSet[curr];
            }

            // Set v1Properties
            if ('properties' === curr) {
              acc.v1Properties = {
                maxClicks: state.adSet.properties.maxClicks,
                maxImpressions: state.adSet.properties.maxImpressions,
                stopAtEndDate: state.adSet.properties.stopAtEndDate,
                priorityId: state.adSet.properties.priorityId,
              };
            }

            if ('rate' === curr) {
              acc.rate = parseFloat(String(state.adSet[curr]));
            }

            // Convert sitePacks to IDs
            if ('sitePacks' === curr) {
              acc.sitePackIds = state.adSet[curr].map((s: api.SitePack) =>
                String(s.id)
              );
            }

            if ('target' === curr) {
              const target = state.adSet[curr];
              const targetVariables = Object.keys(target).reduce(
                (accTarget: any, currTarget: string) => {
                  if (!currTarget || !target[currTarget]) {
                    return accTarget;
                  } else if (['platforms'].includes(currTarget)) {
                    accTarget[currTarget] = target[currTarget];
                  } else if (
                    'categories' === currTarget &&
                    Array.isArray(target[currTarget])
                  ) {
                    // Remove all_categories if exists in categories target array
                    accTarget[currTarget] = target[currTarget].filter(
                      (v) => v !== 'all_categories'
                    );
                  }
                  return accTarget;
                },
                {}
              );

              acc = { ...acc, ...targetVariables };
            }

            return acc;
          }, {}),
        },
      };

      // New geo location save logic
      const locations = getters.geoLocationIds;
      input.variables.geoTargeting = {
        includedCountryIds: locations.includeCountryIds,
        includedStateIds: locations.includeStateIds,
        includedCityIds: locations.includeCityIds,
        excludedCountryIds: locations.excludeCountryIds,
        excludedStateIds: locations.excludeStateIds,
        excludedCityIds: locations.excludeCityIds,
      };

      if (!state.adSet.id) {
        // When a new AdSet is created
        mutation = gql`
          mutation(
            $campaignId: ID!
            ${input.variableDefs}
          ) {
            AdSet: createAdSet(
              campaignId: $campaignId
              input: ${input.args}
            ) {
              ...defaultAdSet
            }
          }
          ${fragments.AdSet.fragments.defaultAdSet}
        `;

        variables = {
          campaignId: state.campaign.id,
          ...input.variables,
        };
      } else {
        // When AdSet already exist and we want to update it
        mutation = gql`
          mutation(
            $id: ID!
            $campaignId: ID!
            ${input.variableDefs}
          ) {
            AdSet: updateAdSet(
              id: $id
              campaignId: $campaignId
              input: ${input.args}
            ) {
              ...defaultAdSet
            }
          }
          ${fragments.AdSet.fragments.defaultAdSet}
        `;

        variables = {
          id: state.adSet.id,
          campaignId: state.campaign.id,
          ...input.variables,
        };
      }
      if (this.app.apolloProvider) {
        const result = await this.app.apolloProvider.defaultClient.mutate({
          mutation,
          variables,
        });
        if (
          result &&
          result.data &&
          result.data.AdSet &&
          result.data.AdSet.id
        ) {
          result.data.AdSet.widgetIds = result.data.AdSet.widgets
            .map((w: any) => w.id)
            .sort();
          result.data.AdSet.widgetGroupIds = result.data.AdSet.widgetGroups
            .map((w: any) => w.groupId)
            .sort();
          if (!state.adSet.id) {
            Vue.prototype.$featureAnalytics.send(AD_SET_CREATED);
          } else {
            Vue.prototype.$featureAnalytics.send(AD_SET_UPDATED);
          }
          commit('SET_AD_SET', result.data.AdSet);
          return result.data.AdSet;
        }
      }
    } catch (e) {
      throw e;
      // not empty
    }
    // Persist campaign
  },
  async archiveAdSet(_, id: string) {
    if (this.app.apolloProvider) {
      await this.app.apolloProvider.defaultClient.mutate({
        mutation: gql`
          mutation ($id: ID!) {
            archiveAdSet(id: $id)
          }
        `,
        variables: {
          id,
        },
      });
      Vue.prototype.$featureAnalytics.send(AD_SET_ARCHIVED);
    }
  },
  async restoreAdSet(_, id: string) {
    if (this.app.apolloProvider) {
      await this.app.apolloProvider.defaultClient.mutate({
        mutation: gql`
          mutation ($id: ID!) {
            restoreAdSet(id: $id)
          }
        `,
        variables: {
          id,
        },
      });
      Vue.prototype.$featureAnalytics.send(AD_SET_RESTORED);
    }
  },
  setAdsOptimizationLevel(
    { commit, state }: ActionContext<IState, IState>,
    level: string
  ) {
    const currentOpt = state.adSet.adsOptimization.replace(/_AGGRESSIVE/, '');
    if (level) {
      level = '_' + level;
    }
    commit('SET_ADS_OPTIMIZATION', currentOpt + level);
  },
};

/********************
 * Campaign         *
 ********************/

const campaignMutations: MutationTree<IState> = {
  FLUSH_CAMPAIGN: (state: IState) => {
    Object.assign(state.campaign, initialState.campaign);
    Object.assign(state.permissions, initialState.permissions);
  },
  SET_CAMPAIGN: (state: IState, campaign: any) => {
    Object.assign(state.campaign, campaign);
  },
  SET_CAMPAIGN_ID: (state: IState, id: string) => (state.campaign.id = id),
  SET_CAMPAIGN_NAME: (state: IState, name: string) =>
    (state.campaign.name = name),
  SET_ADVERTISER: (state: IState, advertiser) =>
    (state.campaign.advertiser = advertiser),
  SET_TRADED_BY: (state: IState, user) => (state.campaign.tradedBy = user),
  SET_TRAFFICKED_BY: (state: IState, user) =>
    (state.campaign.traffickedBy = user),
  SET_CAMPAIGN_IS_HOUSE_AD: (state: IState, isHouseAdCampaign: boolean) => {
    state.campaign.isHouseAdCampaign = isHouseAdCampaign;
  },
};

const campaignGetters = {
  landingPageReporting: (state: IState): boolean => {
    return state.campaign.advertiser.reportSettings &&
      state.campaign.advertiser.reportSettings.landingPage
      ? state.campaign.advertiser.reportSettings.landingPage
      : false;
  },
};

const campaignActions: ActionTree<IState, IState> = {
  flushCampaign: ({ commit }) => commit('FLUSH_CAMPAIGN'),
  setCampaignName: ({ commit }, name: string) => {
    commit('SET_CAMPAIGN_NAME', name);
  },
  setAdvertiser: ({ commit, dispatch }, advertiser: INameID) => {
    commit('SET_ADVERTISER', advertiser);
    dispatch('fetchPermissions', advertiser.id);
  },
  setTradedBy: ({ commit }, user: IUser) => commit('SET_TRADED_BY', user),
  setTraffickedBy: ({ commit }, user: IUser) =>
    commit('SET_TRAFFICKED_BY', user),
  async fetchCampaign(
    { commit, dispatch, state }: ActionContext<IState, IState>,
    id: string
  ) {
    if (this.app.apolloProvider) {
      return this.app.apolloProvider.defaultClient
        .query({
          query: gql`
            query ($id: ID!) {
              Campaign(id: $id) {
                ...defaultCampaign
              }
            }
            ${fragments.Campaign.fragments.defaultCampaign}
          `,
          variables: {
            id,
          },
          fetchPolicy: 'network-only',
        })
        .then((result) => {
          if (
            state.campaign.advertiser.id !== result.data.Campaign.advertiser.id
          ) {
            dispatch('fetchPermissions', result.data.Campaign.advertiser.id);
          }
          commit('SET_CAMPAIGN', result.data.Campaign);
          return result.data.Campaign;
        });
    }
  },
  async saveCampaign({
    commit,
    dispatch,
    state,
  }: ActionContext<IState, IState>) {
    try {
      let mutation: DocumentNode;
      let variables = {};
      let flashMessage: string = '';

      if (!state.campaign.id) {
        // When a new campaign is created
        mutation = gql`
          mutation (
            $advertiserId: ID!
            $name: String!
            $isHouseAdCampaign: Boolean!
            $currency: String!
            $tradedById: ID
            $traffickedById: ID
          ) {
            createCampaign(
              input: {
                advertiserId: $advertiserId
                name: $name
                isHouseAdCampaign: $isHouseAdCampaign
                currency: $currency
                tradedById: $tradedById
                traffickedById: $traffickedById
              }
            ) {
              ...defaultCampaign
            }
          }
          ${fragments.Campaign.fragments.defaultCampaign}
        `;

        variables = {
          name: state.campaign.name,
          advertiserId: state.campaign.advertiser.id,
          isHouseAdCampaign: state.campaign.isHouseAdCampaign || false,
          currency: state.campaign.advertiser.currency,
          tradedById: state.campaign.tradedBy
            ? state.campaign.tradedBy.id
            : null,
          traffickedById: state.campaign.traffickedBy
            ? state.campaign.traffickedBy.id
            : null,
        };

        flashMessage = `Campaign ${state.campaign.name} was created`;
        Vue.prototype.$featureAnalytics.send(CAMPAIGN_CREATED);
      } else {
        // When campaign already exists and we want to update it
        mutation = gql`
          mutation (
            $id: ID!
            $advertiserId: ID!
            $name: String!
            $tradedById: ID
            $traffickedById: ID
          ) {
            updateCampaign(
              id: $id
              input: {
                advertiserId: $advertiserId
                name: $name
                tradedById: $tradedById
                traffickedById: $traffickedById
              }
            ) {
              ...defaultCampaign
            }
          }
          ${fragments.Campaign.fragments.defaultCampaign}
        `;

        variables = {
          id: state.campaign.id,
          name: state.campaign.name,
          advertiserId: state.campaign.advertiser.id,
          tradedById: state.campaign.tradedBy
            ? state.campaign.tradedBy.id
            : null,
          traffickedById: state.campaign.traffickedBy
            ? state.campaign.traffickedBy.id
            : null,
        };

        flashMessage = `Changes saved`;
        Vue.prototype.$featureAnalytics.send(CAMPAIGN_UPDATED);
      }

      if (this.app.apolloProvider) {
        const result = await this.app.apolloProvider.defaultClient.mutate({
          mutation,
          variables,
        });

        if (
          result &&
          result.data &&
          result.data.createCampaign &&
          result.data.createCampaign.id
        ) {
          commit('SET_CAMPAIGN_ID', result.data.createCampaign.id);
        }
        dispatch(
          'setFlash',
          {
            message: flashMessage,
            type: 'success',
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch(
        'setFlash',
        {
          message: ':( Unable to create campaign please try again',
          type: 'error',
        },
        { root: true }
      );
      throw e;
    }
    // Persist campaign
  },
  async archiveCampaign(_, id: string) {
    if (this.app.apolloProvider) {
      await this.app.apolloProvider.defaultClient.mutate({
        mutation: gql`
          mutation ($id: ID!) {
            archiveCampaign(id: $id)
          }
        `,
        variables: {
          id,
        },
      });
      Vue.prototype.$featureAnalytics.send(CAMPAIGN_ARCHIVED);
    }
  },
  async restoreCampaign(_, id: string) {
    if (this.app.apolloProvider) {
      await this.app.apolloProvider.defaultClient.mutate({
        mutation: gql`
          mutation ($id: ID!) {
            restoreCampaign(id: $id)
          }
        `,
        variables: {
          id,
        },
      });
      Vue.prototype.$featureAnalytics.send(CAMPAIGN_RESTORED);
    }
  },
};

/********************
 * Custom           *
 ********************/
const customMutations: MutationTree<IState> = {
  SET_PERMISSIONS: (state: IState, permissions: IPermissions) => {
    state.permissions = permissions;
  },
};

const customActions: ActionTree<IState, IState> = {
  async fetchPermissions(
    { commit }: ActionContext<IState, IState>,
    id: string
  ) {
    if (this.app.apolloProvider) {
      return this.app.apolloProvider.defaultClient
        .query({
          query: gql`
            query ($id: ID!) {
              GetGroupPermissions(groupId: $id)
            }
          `,
          variables: {
            id,
          },
        })
        .then((result) => {
          commit('SET_PERMISSIONS', {
            id,
            permissions: result.data.GetGroupPermissions,
          });
          return result.data.GetGroupPermissions;
        });
    }
  },
};

const debug: boolean = process.env.NODE_ENV !== 'production';

export default {
  state,
  mutations: {
    ...campaignMutations,
    ...adSetMutations,
    ...adMutations,
    ...customMutations,
  },
  actions: {
    ...customActions,
    ...campaignActions,
    ...adSetActions,
    ...adActions,
  },
  getters: { ...campaignGetters, ...adSetGetters, ...adGetters },
  strict: debug,
};
