/**
 * Contains reusable gql fragments.
 *
 * Convention
 *
 * Use Capital letter for full fragments that does not need any key
 * {
 *   ...Advertiser
 * }
 * ${Campaign.fragments.Advertiser}
 *
 * Use small letter for partial fragments
 * {
 *  advertiser {
 *    ...advertiserMeta
 *  }
 * }
 * ${Campaign.fragments.advertiserMeta}
 *
 *
 * NOTE! Order matters when including fragments in other fragments
 */
import gql from 'graphql-tag';
import * as api from '~/shared/types/api';

export const Campaign: any = {
  fragments: {} as {
    Advertiser: Partial<api.Group>;
    defaultCampaign: Partial<api.Campaign>;
  },
};

export const AdSet: any = {
  fragments: {} as {
    defaultAdSet: Partial<api.AdSet>;
    Target: Pick<api.Target, 'platforms'>;
    Widgets: api.Widget;
  },
};

export const SitePack: any = {
  fragments: {} as {
    defaultSitePack: api.SitePack;
  },
};

SitePack.fragments.defaultSitePack = gql`
  fragment defaultSitePack on SitePack {
    id
    name
    type
    language
    priceGuidelines {
      minCPC
      minCPM
      highCPC
      highCPM
      maxCPC
      maxCPM
    }
  }
`;

Campaign.fragments.Advertiser = gql`
  fragment Advertiser on Campaign {
    advertiser {
      id
      name
      language
      currency
      adProvider
      reportSettings {
        landingPage
      }
    }
  }
`;

Campaign.fragments.defaultCampaign = gql`
  fragment defaultCampaign on Campaign {
    id
    name
    isHouseAdCampaign
    currency
    createdAt
    isArchived
    numberOfAdSets
    numberOfArchivedAdSets
    provider
    tradedBy {
      id
      email
    }
    traffickedBy {
      id
      email
    }
    ...Advertiser
  }
  ${Campaign.fragments.Advertiser}
`;

AdSet.fragments.Target = gql`
  fragment Target on AdSet {
    target {
      platforms
      includedGeolocations {
        type
        city {
          id
          name
        }
        state {
          id
          name
        }
        country {
          id
          name
        }
      }
      excludedGeolocations {
        type
        city {
          id
          name
        }
        state {
          id
          name
        }
        country {
          id
          name
        }
      }
      categories
    }
  }
`;

AdSet.fragments.Widgets = gql`
  fragment Widgets on AdSet {
    widgets {
      id
    }
  }
`;

AdSet.fragments.defaultAdSet = gql`
  fragment defaultAdSet on AdSet {
    campaignId
    endDate
    id
    name
    rate
    rateType
    startDate
    status
    deliveryMode
    adsOptimization
    isArchived
    properties {
      ... on AdSetV1Properties {
        maxClicks
        maxImpressions
        stopAtEndDate
        priorityId
      }
    }
    statistics {
      clicks
      impressions
      spent
    }
    sitePacks {
      ...defaultSitePack
    }
    forecast {
      volume
      percentage
    }
    widgetGroups {
      groupId
    }
    ...Target
    ...Widgets
  }
  ${SitePack.fragments.defaultSitePack}
  ${AdSet.fragments.Target}
  ${AdSet.fragments.Widgets}
`;
