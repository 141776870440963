const UNKNOWN_ERROR = 'An unknown error occurred.';

export const handleGraphQLErrors = err => {
  if (!err) {
    return UNKNOWN_ERROR;
  }

  if (
    Array.isArray(err.graphQLErrors) &&
    err.graphQLErrors.length &&
    err.graphQLErrors[0].message !== null
  ) {
    return _cleanMessage(err.graphQLErrors[0].message);
  }

  if (err && err.message) {
    return _cleanMessage(err.message);
  }

  return err;
};

const _cleanMessage = msg => {
  if (!msg || typeof msg !== 'string') {
    return UNKNOWN_ERROR;
  }

  const isFound = ['status code 400', 'caused by:'].find(str =>
    msg.includes(str)
  );

  if (isFound) {
    return UNKNOWN_ERROR;
  }

  return msg;
};

/**
 * Remove __typename recursively from objects
 * @param obj
 * @returns {any}
 */
export const withoutTypename = obj => {
  try {
    const copyUnderWork = JSON.parse(JSON.stringify(obj));
    for (const [key, value] of Object.entries(copyUnderWork)) {
      if (value instanceof Object) {
        copyUnderWork[key] = withoutTypename(value);
      } else if (key === '__typename') {
        delete copyUnderWork[key];
      }
    }
    return copyUnderWork;
  } catch (ex) {
    return obj;
  }
};
