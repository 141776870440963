import { isStrosslerEmail } from '../utils';
export default ({ $ga, store }) => {
  if (!$ga) {
    return;
  }

  /*
   * Subscribe to auth set user mutatition to get user id
   */
  store.subscribe((mutation, state) => {
    if (
      mutation.type === 'auth/SET' &&
      mutation.payload &&
      mutation.payload.key === 'user'
    ) {
      if (
        mutation.payload.value &&
        mutation.payload.value['https://strossle.com/user_id']
      ) {
        const userId = mutation.payload.value['https://strossle.com/user_id']; // This is set in auth0 rule

        // https://matteogabriele.gitbooks.io/vue-analytics/content/docs/set.html
        $ga.set('userId', userId);
      }

      if (mutation.payload.value && mutation.payload.value.email) {
        // See dimension usage in GA.
        $ga.set(
          'dimension1',
          isStrosslerEmail(mutation.payload.value.email) ? 'true': 'false',
        );
      }
    }
  });
};
