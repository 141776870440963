export default ctx => {
  return {
    httpEndpoint: process.env.apolloEndpoint,
    getAuth: () => {
      const strategy = ctx.store.$auth.$state.strategy;
      const bearer = ctx.store.$auth.getToken(strategy);
      return bearer;
    },
  };
};
