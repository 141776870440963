import * as api from '~/shared/types/api';
import { ActionTree, ActionContext, MutationTree } from 'vuex';
import gql from 'graphql-tag';

export interface IState {
  adReviews: api.AdReview[];
  selectedLanguage: string;
}

export const state = (): IState => {
  return {
    adReviews: [],
    selectedLanguage: '',
  };
};

export const mutations: MutationTree<IState> = {
  SET_AD_REVIEWS: (state: IState, adReviews: api.AdReview[]) => {
    state.adReviews = adReviews;
  },
  SET_SELECTED_LANGUAGE: (state: IState, language: string) => {
    state.selectedLanguage = language.toLowerCase();
  },
};

export const actions: ActionTree<IState, IState> = {
  async approveAdReview(
    { state, dispatch }: ActionContext<IState, IState>,
    { id, hash }: { id: string; hash: string }
  ) {
    if (this.app.apolloProvider) {
      await this.app.apolloProvider.defaultClient.mutate({
        mutation: gql`
          mutation ($id: ID!, $hash: String!) {
            approveAdReview(id: $id, hash: $hash)
          }
        `,
        variables: {
          id,
          hash,
        },
      });
      await dispatch('fetchAdReviews', state.selectedLanguage);
    }
  },
  async rejectAdReview(
    { state, dispatch }: ActionContext<IState, IState>,
    {
      id,
      hash,
      rejectMessage,
    }: { id: string; hash: string; rejectMessage: string }
  ) {
    if (this.app.apolloProvider) {
      await this.app.apolloProvider.defaultClient.mutate({
        mutation: gql`
          mutation ($id: ID!, $hash: String!, $rejectMessage: String!) {
            rejectAdReview(id: $id, hash: $hash, rejectMessage: $rejectMessage)
          }
        `,
        variables: {
          id,
          hash,
          rejectMessage,
        },
      });
      await dispatch('fetchAdReviews', state.selectedLanguage);
    }
  },
  async fetchAdReviews(
    { commit }: ActionContext<IState, IState>,
    language: string
  ) {
    if (this.app.apolloProvider) {
      const result = await this.app.apolloProvider.defaultClient.query({
        query: gql`
          query ($language: String!) {
            ListWaitingForApprovalAds(language: $language) {
              adReviews {
                hash
                currentAd {
                  imageUrl
                  hasPlayButton
                  title
                  content
                  brandName
                  imageOverlayText
                  clickUrl
                }
                pendingAd {
                  id
                  imageUrl
                  hasPlayButton
                  title
                  content
                  brandName
                  imageOverlayText
                  clickUrl
                }
                group {
                  id
                  name
                }
                campaign {
                  id
                  name
                  currency
                  provider
                }
                adSet {
                  id
                  name
                  startDate
                  endDate
                  rate
                  rateType
                  properties {
                    ... on AdSetV1Properties {
                      maxClicks
                      maxImpressions
                    }
                    ... on AdSetV2Properties {
                      budget
                      budgetType
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          language,
        },
        fetchPolicy: 'no-cache',
      });
      const data: api.Query = result.data;
      if (
        data &&
        data.ListWaitingForApprovalAds &&
        data.ListWaitingForApprovalAds.adReviews
      ) {
        const adReviews = data.ListWaitingForApprovalAds.adReviews;
        const params = new URLSearchParams(window.location.search);
        const adId = params.get('ad_id');
        for (const ar of adReviews) {
          if (!ar.currentAd) {
            ar.currentAd = {
              id: '',
              campaignId: '',
              adSetId: '',
              name: '',
              externalLink: false,
            };
          }
        }
        if (adId) {
          // Place the selected ad at the top
          const index = adReviews.findIndex((ar) => {
            if (
              ar &&
              ar.pendingAd &&
              ar.pendingAd.id &&
              ar.pendingAd.id === adId
            ) {
              return true;
            }
            return false;
          });
          if (index > -1) {
            const selected = adReviews.splice(index, 1);
            adReviews.unshift(selected[0]);
          }
        }
        commit('SET_AD_REVIEWS', adReviews);
      }
    }
  },
};
