
import Vue from 'vue';
import axios from 'axios';

const appVersion = process.env.version || process.env.sha1 || '';
const appName = process.env.app || null;

export default Vue.extend({
  created() {
    this.getLatestVersion();
    setInterval(this.getLatestVersion, 60 * 1000); // check for new version every 1 minute
  },
  data() {
    return {
      appName,
      appVersion,
      latestVersion: null,
      isDiscarded: false,
    };
  },
  computed: {
    isOldVersion(): boolean {
      if (!this.appVersion || !this.latestVersion || this.isDiscarded) {
        return false;
      }
      console.log(
        `Checking if version is old. This app version is '${this.appVersion}' and latest version is '${this.latestVersion}'`
      );
      return this.appVersion !== this.latestVersion;
    },
  },
  methods: {
    discard() {
      this.isDiscarded = true;
    },
    reload() {
      // Delete version cookie and reload
      document.cookie =
        'ui.version=; expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/';
      window.location.reload();
    },
    getLatestVersion() {
      axios
        .get(`/latest-version.txt?cachebuster=${Math.random()}`)
        .then(response => {
          if (response && response.data) {
            this.latestVersion = response.data.trim();
            console.log('Latest version is', this.latestVersion);
          }
        })
        .catch(err => {
          // do nothing
        });
    },
  },
});
