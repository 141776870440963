<template>
  <div class="quick-navigation">
    <b-dropdown
      size="lg"
      variant="link"
      no-caret
      right
      menu-class="menu-box"
      @hidden="isOpen = false"
      @shown="isOpen = true"
    >
      <template v-slot:button-content>
        <b-img
          :class="{ open: isOpen, closed: !isOpen }"
          src="/img/all-apps-gradient-dots.svg"
          class="rotate"
        />
      </template>
      <b-row cols="2">
        <b-col
          v-for="product in products"
          :key="product.name"
          class="text-center"
        >
          <a :href="product.domain">
            <img
              :src="product.icon.url"
              :alt="product.description"
              :title="product.description"
              class="icon"
            />
            <div class="icon-text">{{ product.name }}</div>
          </a>
        </b-col>
      </b-row>
    </b-dropdown>
  </div>
</template>

<script>
import settings from '~shared/settings';

export default {
  data() {
    return {
      isOpen: false,
      products: this.$settings.get('appSettings.quickNavigation.items'),
    };
  },
};
</script>

<style scoped>
.rotate {
  transition: all 0.1s ease-in;
}
.open {
  transform: rotate(90deg);
}
.closed {
  transform: rotate(-90deg);
}
.quick-navigation >>> .dropdown-menu {
  width: 250px !important;
  padding: 10px;
}
.icon {
  max-width: 75px;
  max-height: 75px;
}
.icon-text {
  font-weight: 600;
  margin-top: 1em;
}
a {
  text-decoration: none;
  color: #2f353a;
}
</style>
