<template>
  <div class="app">
    <AppHeader fixed>
      <b-link class="navbar-brand" to="">
        <img
          class="navbar-brand-full"
          src="/img/strosslelogo.svg"
          width="auto"
          height="18"
          alt="Strossle"
        />
        <img
          class="navbar-brand-minimized"
          src="/img/strosslelogominimized.svg"
          width="auto"
          height="20"
          alt="Strossle"
        />
      </b-link>
    </AppHeader>
    <div class="app-body">
      <main class="main py-4">
        <div class="container pt-5">
          <nuxt />
        </div>
      </main>
    </div>
    <vue-snotify />
  </div>
</template>

<script>
import AppHeader from '~shared/components/commons/AppHeader.vue';
export default {
  components: {
    AppHeader,
  },
  data() {
    return {};
  },
  mounted() {
    document.body.classList.add('pl-0');
  },
};
</script>
<style scoped lang="scss">
.app-header {
  border: none;
}
.main {
  background: #f0f3f5 !important;
}
</style>
