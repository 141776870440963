import { defaults, leadbooster } from '../settings';
import { isStrosslerEmail } from '../utils';

/**
 * Global settings plugin injected in the app.
 * Contains appSettings and userSettings.
 *
 * Usage in templates: $settings.get('appSettings.logo.url')
 * Load settings $settings.load({networkId})
 *
 * @param context
 * @param inject
 */
export default ({ $auth }, inject) => {
  let settings = _networkIdSettings[1];

  const methods = {
    get: dotNotationPath => {
      let obj = settings || {};
      const arr = dotNotationPath.split('.');
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    },

    load: id => {
      settings = _networkIdSettings[id];
    },

    /**
     * Special function to get navigation items.
     * It will filter items based on if user is a strossle user or not.
     * Depends on the item including strossleOnly key.
     * Example an item strossleOnly: true will only be returned if the user
     * has an email of @strossle.com
     * @param items
     * @returns {*}
     */
    getNavigationItems: dotNotationPath => {
      const items = methods.get(dotNotationPath) || [];
      const isStrossler =
        $auth &&
        $auth.user &&
        $auth.user.email &&
        isStrosslerEmail($auth.user.email);
      return items.filter(
        item => !item.strossleOnly || (item.strossleOnly && isStrossler)
      );
    },
  };

  if (
    $auth &&
    $auth.user &&
    $auth.user['https://strossle.com/networks'] &&
    $auth.user['https://strossle.com/networks'].length === 1
  ) {
    const networkId = $auth.user['https://strossle.com/networks'][0] || 1;
    methods.load(networkId);
  }

  // globally inject settings instance
  inject('settings', methods);
};

const _networkIdSettings = {
  1: defaults,
  2: leadbooster,
};
