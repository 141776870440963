
import defaultLayout from '~shared/layouts/default.vue';
import MarketplaceSearch from '../components/MarketplaceSearch.vue';

export default {
  components: {
    defaultLayout,
    MarketplaceSearch,
  },
  data() {
    return {
      navigationItems:
        (this as any).$settings.getNavigationItems(
          'appSettings.products.marketplace.navigation.items'
        ) || [],
    };
  },
};
