import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';

export default ({ store }, inject) => {
  const logrocketId = process.env.logrocket;
  const release = process.env.version || process.env.sha1;

  if (!logrocketId) {
    return;
  }

  if (['production', 'staging'].includes(process.env.environment)) {
    LogRocket.init(logrocketId, {
      release,
      rootHostname: 'strossle.com',
      shouldCaptureIP: false,
      network: {
        requestSanitizer: (request) => {
          // if the url contains 'public/signup'
          if (request.url.toLowerCase().indexOf('public/signup') !== -1) {
            // scrub out the body
            request.body = null;
          }

          if (request.headers['authorization']) {
            request.headers['authorization'] = '';
          }

          if (request.headers['Authorization']) {
            request.headers['Authorization'] = '';
          }

          return request;
        },
      },
    });
  } else {
    console.log('Skip Logrocket.');
  }

  if (store) {
    const logrocketPlugin = createPlugin(LogRocket);
    // add plugin to vuex store
    logrocketPlugin(store);

    store.subscribe((mutation) => {
      if (
        mutation.type === 'auth/SET' &&
        mutation.payload &&
        mutation.payload.key === 'user' &&
        mutation.payload.value
      ) {
        const userId = mutation.payload.value['https://strossle.com/user_id'];
        const domain = ((email) => {
          if (!email) {
            return;
          }
          const split = email.split('@');
          return split.length > 1 ? split[1] : '';
        })(mutation.payload.value.email);
        LogRocket.identify(userId, {
          name: domain,
        });
      }
    });
  }

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('logrocket', sessionURL);
    });
  });

  // globally inject LogRocket instance
  inject('logRocket', LogRocket);
};

/**
 * https://docs.logrocket.com/reference#track
 * @param str
 */
export const track = (str) => {
  LogRocket.track(str);
};
