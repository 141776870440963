<template>
  <b-container class="d-flex align-items-center h-100">
    <b-row class="mx-auto">
      <b-col class="text-center">
        <h1 v-if="!error"><!-- Empty handler must be here --></h1>
        <h1 v-else-if="error.message === 'ERR_REDIRECT'"></h1>
        <h1 v-else-if="error.statusCode === 404">Page not found</h1>
        <h1 v-else-if="error.statusCode === 428">Precondition failed</h1>
        <h1 v-else-if="error.statusCode === 503">Service unavailable</h1>
        <div v-else-if="error.statusCode === 500">
          <p class="my-3">
            <mark>:scream_cat:</mark>
          </p>

          <p>
            Please email us and describe what you did before you saw this error
            and we will do our best to solve it.
          </p>
          <p>
            <a href="mailto:servicedesk@strossle.com"
              >servicedesk@strossle.com</a
            >
          </p>
        </div>
        <h1 v-else-if="error.message">{{ error.message }}</h1>
        <h1 v-else>An error occurred</h1>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  auth: false,
  props: ['error'],
};
</script>

<style scoped>
html,
body,
#__nuxt,
#__nuxt #__layout {
  height: 100vh;
}
</style>
