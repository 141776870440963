import Vue from 'vue';
import { track as logrocketTrack } from './logrocket';
/**
 * Event tracker plugin.
 * Analytics plugin for sending events to various services.
 *
 * @param context
 * @param inject
 */
const FeatureAnalytics = {
  // eslint-disable-next-line no-shadow
  install(vue, options) {
    vue.prototype.$featureAnalytics = {
      send: function(evt) {
        logrocketTrack(evt);
      },
    };
  },
};

Vue.use(FeatureAnalytics);
