<template>
  <div
    class="app-header fixed-top"
    :style="noColor ? 'background-color:#f0f3f5' : ''"
  >
    <div v-if="$slots.search" :class="mobileSearchClasses">
      <slot name="search" />
    </div>
    <button
      @click="toggleMenu"
      class="header-toggle-menu btn mx-2 float-left mt-1"
      type="button"
    >
      <fa-icon v-if="isMenuOpen" icon="xmark" />
      <fa-icon v-else icon="bars" />
    </button>
    <nuxt-link :to="{ path: '/' }" class="float-left mt-2">
      <img
        src="https://assets.strossle.com/images/strossle_logo_azerion.png"
        class="navbar-brand-full ml-0 ml-md-4 mt-1"
        width="118"
        alt="Strossle"
      />
    </nuxt-link>
    <div class="float-right d-flex flex-row">
      <span v-if="$slots.search">
        <span :class="searchInputClasses" style="width: 400px" class="mt-2">
          <slot name="search"></slot>
        </span>
        <span
          :class="searchIconClasses"
          @click="clickIcon"
          style="margin-top:0.75rem;"
        >
          <fa-icon icon="magnifying-glass" />
        </span>
      </span>
      <span v-if="$slots.actions">
        <slot name="actions" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
    toggleMenu: {
      type: Function,
      default: () => {},
    },
    noColor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMessage: false,
      logo: this.$settings.get('appSettings.logo.url'),
      logoSmall: this.$settings.get('appSettings.logoSmall.url'),
      mobileSearchClasses: ['d-none'],
      searchInputClasses: ['d-none', 'd-lg-block'],
      searchIconClasses: ['d-block', 'd-lg-none', 'search-icon'],
    };
  },
  mounted() {
    this.$root.$on('mobile-search-unfocused', () => {
      this.mobileSearchClasses = ['d-none'];
    });
  },
  methods: {
    setSidebarStateInLocalStorage(value) {
      window.localStorage.setItem('sidebar-minimized', value);
    },
    async clickIcon() {
      this.mobileSearchClasses = ['mobile-search'];
      await this.$nextTick();
      this.$root.$emit('mobile-search-focus');
    },
  },
};
</script>

<style scoped lang="scss">
.app-header {
  padding-top: 0.2rem;
  background-color: #fff;
  top: 0;
  right: 0;
  border-bottom: 1px solid #ccc;
  transition: width 0.25s;
  min-height: 3.3rem;
}
@media screen and (min-width: 768px) {
  .header-toggle-menu {
    display: none;
  }
}
.search-icon {
  color: #4d92e3;
  cursor: pointer;
}

.mobile-search {
  width: 100vw;
  z-index: 1062;
  background-color: white;
  position: fixed;
  height: 55px;
  padding: 0.5rem;
}
</style>
