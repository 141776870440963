<template>
  <div class="app">
    <app-header
      fixed
      style="z-index: 1061"
      :is-menu-open="isMenuOpen"
      :toggle-menu="updateIsMenuOpen"
    >
      <template slot="search">
        <slot name="search" />
      </template>
      <template slot="actions">
        <div v-if="profile" class="float-right d-flex flex-row" data-private>
          <quick-navigation />
          <lighthouse-widget class="mt-2" />
          <dropdown variant="link">
            <template slot="button-content">
              <b-img
                :src="profile.picture"
                :alt="profile.email"
                class="profile-avatar"
              />
            </template>
            <b-dropdown-item @click="logout">
              <i class="fa fa-lock" /> Log out
            </b-dropdown-item>
          </dropdown>
        </div>
      </template>
    </app-header>
    <app-menu
      :is-menu-open="isMenuOpen"
      :menu-items="navigationItems"
      :toggle-menu="updateIsMenuOpen"
    />

    <div class="main-container container-fluid">
      <flash-messages />
      <version-notifier />
      <nuxt />
    </div>
    <vue-snotify />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import VersionNotifier from '../components/VersionNotifier';
import QuickNavigation from '../components/QuickNavigation';
import LighthouseWidget from '~shared/components/LighthouseWidget.vue';
import FlashMessages from '../components/FlashMessages.vue';
import AppHeader from '../components/commons/AppHeader.vue';
import AppMenu from '../components/commons/AppMenu.vue';
import Dropdown from '../components/Dropdown.vue';

export default {
  components: {
    AppHeader,
    AppMenu,
    VersionNotifier,
    QuickNavigation,
    LighthouseWidget,
    FlashMessages,
    Dropdown,
  },
  props: ['navigationItems'],
  computed: {
    ...mapState('auth', {
      profile: (state) => state.user,
      isLoggedIn: (state) => state.loggedIn,
    }),
    ...mapGetters('menu', {
      isMenuOpen: 'isMenuOpen',
    }),
    getYear() {
      return new Date().getFullYear();
    },
    flash() {
      return this.$store.state.flash;
    },
  },
  watch: {
    flash() {
      if (this.flash) {
        const toast = this.$snotify[this.flash.type](
          this.flash.message,
          this.flash.title,
          this.flash.options
        );
        if (toast) {
          toast.on('destroyed', () => this.$store.commit('FLUSH_FLASH'));
        }
      }
    },
  },
  methods: {
    ...mapActions('menu', {
      updateIsMenuOpen: 'updateIsMenuOpen',
    }),
    logout() {
      this.$auth.logout();
    },
  },
};
</script>

<style>
.dropdown-menu-right > li > a {
  min-width: 0px !important;
}
</style>

<style scoped lang="scss">
@media screen and (min-width: 922px) {
  .main-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.app {
  background-color: #f0f3f5;
  min-height: 100vh;
}
.main-container {
  max-width: 1600px;
  padding-top: 72px;
  padding-bottom: 3rem;
}

.profile-avatar {
  height: 35px;
  border-radius: 50%;
}
</style>
