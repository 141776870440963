import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faAngleLeft as proFasFaAngleLeft,
  faAngleRight as proFasFaAngleRight,
  faTriangleExclamation as proFasFaTriangleExclamation,
  faCheck as proFasFaCheck,
  faPencil as proFasFaPencil,
  faEllipsis as proFasFaEllipsis,
  faCircleExclamation as proFasFaCircleExclamation,
  faCircleXmark as proFasFaCircleXmark,
  faCircleInfo as proFasFaCircleInfo,
  faCircleQuestion as proFasFaCircleQuestion,
  faCirclePause as proFasFaCirclePause,
  faCirclePlay as proFasFaCirclePlay,
  faXmark as proFasFaXmark,
  faBars as proFasFaBars,
  faMagnifyingGlass as proFasFaMagnifyingGlass,
  faBoxArchive as proFasFaBoxArchive,
  faSpinner as proFasFaSpinner,
  faPlus as proFasFaPlus,
  faTrash as proFasFaTrash,
  faClipboardCheck as proFasFaClipboardCheck,
  faArrowUpRightFromSquare as proFasFaArrowUpRightFromSquare,
  faChevronDown as proFasFaChevronDown,
  faUserPlus as proFasFaUserPlus,
  faClone as proFasFaClone,
  faChartBar as proFasFaChartBar,
  faHeart as proFasFaHeart,
  faShareNodes as proFasFaShareNodes,
  faArrowRotateLeft as proFasFaArrowRotateLeft,
  faCircleCheck as proFasFaCircleCheck,
  faCircleMinus as proFasFaCircleMinus,
  faCaretDown as proFasFaCaretDown,
  faClipboard as proFasFaClipboard,
  faCloudArrowUp as proFasFaCloudArrowUp,
  faLinkSimple as proFasFaLinkSimple
} from '@fortawesome/pro-solid-svg-icons'

import {
  faClipboard as proFarFaClipboard,
  faCircleQuestion as proFarFaCircleQuestion
} from '@fortawesome/pro-regular-svg-icons'

import {
  faList as proFalFaList,
  faCirclePlus as proFalFaCirclePlus,
  faCircleCheck as proFalFaCircleCheck,
  faGlobe as proFalFaGlobe
} from '@fortawesome/pro-light-svg-icons'

library.add(
  proFasFaAngleLeft,
  proFasFaAngleRight,
  proFasFaTriangleExclamation,
  proFasFaCheck,
  proFasFaPencil,
  proFasFaEllipsis,
  proFasFaCircleExclamation,
  proFasFaCircleXmark,
  proFasFaCircleInfo,
  proFasFaCircleQuestion,
  proFasFaCirclePause,
  proFasFaCirclePlay,
  proFasFaXmark,
  proFasFaBars,
  proFasFaMagnifyingGlass,
  proFasFaBoxArchive,
  proFasFaSpinner,
  proFasFaPlus,
  proFasFaTrash,
  proFasFaClipboardCheck,
  proFasFaArrowUpRightFromSquare,
  proFasFaChevronDown,
  proFasFaUserPlus,
  proFasFaClone,
  proFasFaChartBar,
  proFasFaHeart,
  proFasFaShareNodes,
  proFasFaArrowRotateLeft,
  proFasFaCircleCheck,
  proFasFaCircleMinus,
  proFasFaCaretDown,
  proFasFaClipboard,
  proFasFaCloudArrowUp,
  proFasFaLinkSimple,
  proFarFaClipboard,
  proFarFaCircleQuestion,
  proFalFaList,
  proFalFaCirclePlus,
  proFalFaCircleCheck,
  proFalFaGlobe
)

config.autoAddCss = false

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('fa-iconLayers', FontAwesomeLayers)
Vue.component('fa-iconLayersText', FontAwesomeLayersText)
